import React from 'react';
import {
    Button, CardBody, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import Util from "../inc/utils";
import AppSwitch from "@coreui/react/es/Switch";
import InputSeach2 from "./widgets/input_search2";

const CATEGORY = {
    'id': 0,
    'name': '',
    'detail': '',
    'sort': '',
};

const STATE = {
    item: {
        id: 0,
        name: '',
        detail: '',
        category: CATEGORY,
        required: '0',
        sort: '0'
    },
    type: false,
    loading: false,
    error: null,
    errors: {},
    modal: false,
};

export default class CategoriesM extends _component {

    state = clone(STATE);

    add = () => {
        this.setState({
            ...clone(STATE),
            modal: true,
        });
    };

    edit = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/categories/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                this.setState({
                    ...this.state,
                    loading: false,
                    item: rsp.item,
                    type: rsp.item.category.id == 0 ? true : false,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        var errors = new Object();
        const category = this.state.item.category;

        if (!this.state.type && category.id == 0) {
            errors.category = 'Selecciona una categoria';
        }

        if (Object.keys(errors).length === 0) {
            const item = {...this.state.item};
            const data = new FormData();
            Object.keys(item).map(key => data.append(key, item[key]));
            data.append('id_category', this.state.type ? 0 : item.category.id);

            Api.post('/categories/create', data, (rsp) => {
                if (rsp.ok) {
                    toast.success('Guardado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback({
                        ...this.state.item,
                        id: rsp.id
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        error: rsp.msg,
                        errors: rsp.errors
                    });
                }
            });
        } else {
            this.setState({
                ...this.state,
                loading: false,
                errors: errors
            });
        }
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/categories/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...');
        });
    };

    callbackCategory = (rsp) => {
        this.setState({
            item: {
                ...this.state.item,
                category: rsp.item
            }
        });
    };

    render() {
        const {item, type, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}>
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} categoria
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <div>Tipo</div>
                                    <AppSwitch
                                        className="mdl"
                                        color="primary"
                                        variant="pill"
                                        checked={type}
                                        onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                type: e.target.checked ? true : false,
                                                item: {
                                                    ...this.state.item,
                                                    required: !e.target.checked ? 0 : this.state.item.required
                                                }
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            {this.state.type == true && (
                                <Col md={3}>
                                    <FormGroup>
                                        <div>Requerido</div>
                                        <AppSwitch
                                            className="mdl"
                                            color="primary"
                                            variant="pill"
                                            checked={item.required == 1}
                                            onChange={(e) => {
                                                this.setState({
                                                    item: {
                                                        ...this.state.item,
                                                        required: e.target.checked ? 1 : 0
                                                    }
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                            <Col sm={9}></Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label onClick={() => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                category: {
                                                    ...this.state.item.category,
                                                    name: 'update'
                                                }
                                            }
                                        });
                                        console.log("Click");
                                    }}>Nombre</label>
                                    <Input name="name" value={item.name} onChange={this.changed}
                                           invalid={errors.name != null}/>
                                    <FormFeedback>{errors.name}</FormFeedback>
                                </FormGroup>
                            </Col>

                            {this.state.type == false && (
                                <Col md={6}>
                                    <FormGroup>
                                        <label>Categoria</label>
                                        <InputSeach2
                                            endpoint="/categories/search"
                                            inputName="name"
                                            initialValue={this.state.item.category.name}
                                            item={this.state.item.category}
                                            invalid={errors.category}
                                            callback={this.callbackCategory}
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                            <Col md={12}>
                                <FormGroup>
                                    <Label>Detalle</Label>
                                    <Input type='textarea'
                                           rows={2}
                                           name="detail"
                                           value={item.detail}
                                           onChange={this.changed}/>
                                    <FormFeedback>{errors.detail}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
