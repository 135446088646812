import React from 'react';
import {
    Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Progress,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupAddon, Input, Badge
} from 'reactstrap';
import ButtonState from "./widgets/button_state";
import OccupyRoomM from "./OccupyRoomM";
import CleaningRoomM from "./CleaningRoomM";
import ActiveRoomM from "./ActiveRoomM";
import {User} from "../inc/user";
import {Api} from "../inc/api";
import {BarLoader} from "react-spinners";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import Select from "react-select";
import Util from "../inc/utils";

export default class Reception extends React.Component {
    state = {
        canEdit: User.can(this),
        state: '',
        filter: [],
        categories: [],
        items: [],
        category: '0',
        box: {},
        loading: true,
        loadingButton: true,
        error: null,
        dropdownOpen: false
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }


    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    getBedrooms = (state, filter) => {
        if (typeof filter === 'undefined') {
            filter = false;
        }

        if (state === "refresh") {
            this.setState({
                ...this.state,
                loadingButton: true,
            });
            state = this.state.state;
        } else {
            this.setState({
                ...this.state,
                state: state,
                loadingButton: true,
            });
        }

        const data = new FormData();
        data.append('state', state);
        data.append('filter', JSON.stringify(this.state.filter));

        Api.post('/reception', data, (rsp) => {
            if (rsp.ok) {
                let categories = this.state.categories;
                let options = this.state.filter;

                if (filter) {
                    categories = rsp.categories;
                    options = [];

                    rsp.categories.map((o, i) => {
                        let option = new Object();
                        option.category = o.id;
                        option.value = 0;
                        options.push(option);
                    });
                }

                this.setState({
                    loading: false,
                    loadingButton: false,
                    box: rsp.box,
                    categories: categories,
                    filter: options,
                    items: rsp.items
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    loadingButton: false,
                    error: rsp.msg
                });
            }
        }, false);
    };

    cleaning = (id) => {
        Alert.confirm(() => {
            Alert.loading();
            Api.post('/reception/cleaning', {id: id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Habitación limpiada correctamente');
                    this.getBedrooms("refresh");
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Limpiando...');
        });
    };

    cleanAll = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.post('/reception/clean_all', rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Habitaciones limpiadas correctamente');
                    this.getBedrooms("refresh");
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Limpiando...');
        });
    };

    getIndex = (category) => {
        let categories = this.state.filter;
        let index = -1;
        categories.map((o, i) => {
            if (o.category == category.id) {
                return index = i;
            }
        });
        return index;
    };

    componentWillMount() {
        this.getBedrooms('', true);
        this.myInterval = setInterval(() => {
            this.getBedrooms('refresh');
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    render() {
        const {box, items, categories, filter} = this.state;
        return (
            <div>
                <Card className="_card">
                    <CardHeader>
                        <span className="text-uppercase bold">RECEPCIÓN</span>
                        <div className="card-header-actions">
                            <ButtonState
                                {...this.props}
                                color="dark"
                                icon="check-circle-o"
                                name="Todo"
                                active={this.state.state == "" ? true : false}
                                function={() => this.getBedrooms("")}
                            />
                            <ButtonState
                                {...this.props}
                                color="success"
                                icon="arrow-circle-left"
                                name="Disponible"
                                active={this.state.state == 1 ? true : false}
                                function={() => this.getBedrooms(1)}
                            />
                            <ButtonState
                                {...this.props}
                                color="danger"
                                icon="arrow-circle-right"
                                name="Ocupado"
                                active={this.state.state == 2 ? true : false}
                                function={() => this.getBedrooms(2)}
                            />
                            <ButtonState
                                {...this.props}
                                color="info"
                                icon="spinner"
                                name="Limpieza"
                                active={this.state.state == 3 ? true : false}
                                function={() => this.getBedrooms(3)}
                            />

                            <Dropdown isOpen={this.state.dropdownOpen}
                                      toggle={this.toggle}
                                      className="mr-2"
                                      style={{'display': 'inline'}}>
                                <DropdownToggle caret>Acción</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className="pointer" onClick={this.cleanAll}>Limpiar
                                        todo</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            {this.state.loadingButton ?
                                <button className="text-uppercase btn btn-info"
                                        onClick={() => this.getBedrooms("refresh")}>
                                    <i className="fa fa-refresh"/>
                                </button> :
                                <button className="text-uppercase btn btn-primary"
                                        onClick={() => this.getBedrooms("refresh")}>
                                    <i className="fa fa-refresh"/>
                                </button>
                            }
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">
                        <span hidden={!this.state.loading}>
                            <BarLoader width={-1} height={3}/>
                            <div className="mb-1"></div>
                        </span>

                        <Row>
                            {categories.map((o, i) => {
                                o.items.forEach(o => {
                                    o.value = o.id;
                                    o.label = o.name;
                                });

                                let index = this.getIndex(o);

                                if (index >= 0) {
                                    return categories.length > 0 && (<Col md={3}>
                                        <FormGroup>
                                            <Label>{o.name}</Label>
                                            <Select
                                                classNamePrefix="react-select"
                                                placeholder="Buscar..."
                                                options={o.items}
                                                value={categories[index].items.filter(o => o.value === this.state.filter[index].value)}
                                                onChange={selected => {
                                                    if (selected) {
                                                        let option = new Object();
                                                        option.category = o.id;
                                                        option.value = selected.id;
                                                        filter.splice(index, 1, option);
                                                        this.setState(this.state);
                                                        this.getBedrooms('refresh');
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>)
                                }
                            })}

                            {categories.length > 0 && (
                                <Col sm={12}>
                                    <hr className="m-0 mb-3"/>
                                </Col>
                            )}

                            {box.id == 0 && (
                                <Col sm={12}>
                                    <div className="alert alert-danger">{box.msg}</div>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            {!this.state.loading && items.length == 0 && (
                                <Col md={12}>
                                    <div className="alert alert-warning">
                                        Na hay resultados.
                                    </div>
                                </Col>
                            )}

                            {items.map((o, i) => (
                                o.state == 1 ?
                                    <div className="col-6 col-lg-3" key={i}>
                                        <div className="card"
                                             style={{'cursor': 'pointer'}}
                                             onClick={() => this.occupyroom.add(o.id)}>
                                            <div className="card-body p-3 d-flex align-items-center">
                                                <i className="bg-success p-2 mr-3"
                                                   style={{'borderRadius': '5px'}}>
                                                    <img src='img/room_available.png' alt="" width="40"/>
                                                </i>
                                                <div>
                                                    <div className="text-value-sm text-info">{o.name}</div>
                                                    <div
                                                        className="text-muted text-uppercase font-weight-bold small">Disponible
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer px-3 py-2">
                                                <div
                                                    className="btn-block text-muted d-flex justify-content-between align-items-center">
                                                    <span className="small font-weight-bold">Ocupar</span>
                                                    <span className="small font-weight-bold">
                                                        {User.stg.coin + '' + Util.num(o.price, 2)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : o.state == 2 ?
                                    <div className="col-6 col-lg-3">
                                        <div className="card" style={{'cursor': 'pointer'}}
                                             onClick={() => this.activeroom.add(o.id)}>
                                            <div className="card-body p-3 d-flex align-items-center">
                                                <i className="bg-danger p-2 mr-3"
                                                   style={{'borderRadius': '5px'}}>
                                                    <img src='img/room_not_available.png' alt="" width="40"/>
                                                </i>
                                                <div>
                                                    <div className="text-value-sm text-info">{o.name}</div>
                                                    <div className="text-muted text-uppercase font-weight-bold small">
                                                        Ocupado
                                                    </div>
                                                    <Progress color={o.progress}
                                                              value={o.progress_consumed}
                                                              max={o.progress_total}/>
                                                </div>
                                            </div>
                                            <div className="card-footer px-3 py-2">
                                                <div
                                                    className="btn-block text-muted d-flex justify-content-between align-items-center">
                                                    <span className="small font-weight-bold">Desocupar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : o.state == 3 ?

                                        <div className="col-6 col-lg-3">
                                            <div className="card" style={{'cursor': 'pointer'}}>
                                                <div className="card-body p-3 d-flex align-items-center"
                                                     onClick={() => this.cleaningroom.add(o.id)}>
                                                    <i className="bg-info p-2 mr-3"
                                                       style={{'borderRadius': '5px'}}>
                                                        <img src='img/clean.png' alt="" width="40"/>
                                                    </i>
                                                    <div>
                                                        <div className="text-value-sm text-info">{o.name}</div>
                                                        <div
                                                            className="text-muted text-uppercase font-weight-bold small">Limpieza
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer px-3 py-2"
                                                     onClick={() => this.cleaning((o.id))}>
                                                    <div
                                                        className="btn-block text-muted d-flex justify-content-between align-items-center">
                                                        <span className="small font-weight-bold">Limpiar</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <i></i>

                            ))}
                        </Row>
                    </CardBody>
                </Card>

                <OccupyRoomM ref={ins => this.occupyroom = ins}
                             callback={() => this.getBedrooms("refresh")}/>

                <CleaningRoomM ref={ins => this.cleaningroom = ins}
                               callback={() => this.getBedrooms("refresh")}/>

                <ActiveRoomM ref={ins => this.activeroom = ins}
                             callback={() => this.getBedrooms("refresh")}/>
            </div>)
    }
}