import React, {Component} from 'react';
import {Col, FormFeedback, Input,} from 'reactstrap';
import PropTypes from 'prop-types';
import {FormGroup} from "reactstrap";
import {Api} from "../../inc/api";

export default class InpurSearch extends Component {

    state = {
        focus: false,
        endpoint: '',
        active: '',
        title: '',
        subtitle: '',
        inputValue: '',
        placeholder: '',
        invalid: '',
        messageEmpty: '',
        items: [],
        item: {}
    };

    constructor(props) {
        super(props);
    }

    onFocus = () => {
        this.setState({
            focus: true,
            item: this.props.item,
        });
    };

    select = async (item) => {
        let active = await this.filterDate(item).active;

        if (active === '') {
            active = await this.filterDate(item).title;
        }

        await this.setState({
            ...this.state,
            inputValue: active
        });
        this.callback(item);
    };

    onBlur = () => {
        if (this.state.items.length === 0) {
            this.callback(this.state.item);
        } else {
            setTimeout(() => {
                if (this.state.focus) {
                    this.callback(this.state.item);
                }
            }, 200);
        }
    };

    close = () => {
        console.log('close: ', this.state.item);
        this.callback(this.state.item);
    };

    callback = (item = {}) => {
        console.log('callback_item: ', this.state.item);
        let rsp = {
            'inputValue': this.state.inputValue,
            'stateItem': Object.keys(item).length === 0 ? false : true,
            'item': item,
            'items': this.state.items,
        };
        this.props.callback(rsp);
        this.setState({focus: false});
    };

    changed = async (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let newValue = value.replace(/\W/g, '');
        let items = [];

        await this.setState({
            ...this.state,
            [name]: newValue,
        });

        if (newValue.length > 0) {
            items = await this.query(newValue);
        } else {
            items = []
        }

        this.setState({
            ...this.state,
            items: items,
            item: {
                ...this.state.item,
                id: 0
            }
        });
    };

    query = (word) => {
        return new Promise(resolve => {
            Api.get(this.state.endpoint, {word: word}, rsp => {
                if (rsp.ok) {
                    resolve(rsp.items);
                } else {
                    resolve([]);
                }
            }, false);
        });
    };

    filterDate = (o) => {
        let objActive = '';
        let objTitle = '';
        let objSubtitle = '';
        Object.keys(o).map(key => {
            if (key == this.state.active) {
                objActive = o[key];
            }
            if (key == this.state.title) {
                objTitle = o[key];
            }
            if (key == this.state.subtitle) {
                objSubtitle = o[key];
            }
        });
        return {'active': objActive, 'title': objTitle, 'subtitle': objSubtitle};
    };

    loadData = () => {
        let endpoint = this.props.endpoint;
        let active = this.props.active;
        let title = this.props.title;
        let subtitle = this.props.subtitle;
        let inputValue = this.props.inputValue;
        let placeholder = this.props.placeholder;
        let messageEmpty = this.props.messageEmpty;

        if (typeof endpoint === 'undefined') {
            endpoint = '';
        }

        if (typeof active === 'undefined') {
            active = '';
        }

        if (typeof title === 'undefined') {
            title = ''
        }

        if (typeof subtitle === 'undefined') {
            subtitle = '';
        }

        if (typeof inputValue === 'undefined') {
            inputValue = '';
        }

        if (typeof placeholder === 'undefined') {
            placeholder = '';
        }

        if (typeof messageEmpty === 'undefined') {
            messageEmpty = '';
        }

        this.setState({
            ...this.state,
            endpoint: endpoint,
            active: active,
            title: title,
            subtitle: subtitle,
            inputValue: inputValue,
            placeholder: placeholder,
            messageEmpty: messageEmpty,
        });
    };

    border = () => {
        const totalItems = this.state.items.length;
        const messageEmpty = this.state.messageEmpty;
        let border = 'border';

        if (totalItems == 0 && messageEmpty == '') {
            border = '';
        }

        return border;
    };

    componentWillMount() {
        this.loadData();
    }

    render() {
        const border = this.border();
        const results = this.state.items.length;
        return (
            <FormGroup>
                <Input
                    name="inputValue"
                    autocomplete="off"
                    invalid={this.props.invalid != null}
                    value={this.state.inputValue}
                    placeholder={this.state.placeholder}
                    onChange={this.changed}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                />
                <FormFeedback>{this.props.invalid}</FormFeedback>

                {this.state.focus && (
                    <div className="mt-2"
                         style={{
                             'position': 'relative',
                             'background': '',
                             'height': '0px'
                         }}>
                        <div
                            className={border}
                            style={{
                                'position': 'absolute',
                                'background': 'white',
                                'width': '100%',
                                'zIndex': '9999999999',
                                'maxHeight': '250px',
                                'overflow': 'hidden',
                                'overflowY': 'auto',
                                'borderRadius': '5px',
                            }}>

                            {(this.state.items.length == 0 && this.state.messageEmpty != '') && (
                                <p className="p-2 lead">{this.state.messageEmpty}</p>
                            )}

                            {this.state.items.length > 0 && (
                                <div>
                                    <li className="d-flex list-group-item-action justify-content-between align-items-center p-2 bold"
                                        style={{'background': '#F0F3F5'}}
                                        onClick={this.close}>
                                        <span>{results + ' ' + (results > 1 ? 'resultados' : 'resultado')}</span>
                                        <span className="badge badge-pill">
                                            <i className="fa fa-times" style={{'cursor': 'pointer'}}/>
                                        </span>
                                    </li>
                                    <hr className="m-0"/>
                                </div>
                            )}

                            {this.state.items.map((o, i) => (
                                <li key={i}
                                    className="d-flex list-group-item-action justify-content-between align-items-center p-2"
                                    onClick={() => this.select(o, i)}
                                    style={{'cursor': 'pointer'}}>
                                    {this.filterDate(o).title}
                                    {this.state.subtitle != '' && (
                                        <span className="badge badge-pill">{this.filterDate(o).subtitle}</span>
                                    )}
                                </li>
                            ))}
                        </div>
                    </div>
                )}
            </FormGroup>
        );
    }
}

InpurSearch.propTypes = {
    active: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    item: PropTypes.object,
    inputValue: PropTypes.string,
    placeholder: PropTypes.string,
    invalid: PropTypes.string,
    messageEmpty: PropTypes.string,
    endpoint: PropTypes.string,
    callback: PropTypes.func
};
