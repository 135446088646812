import Clients from "./views/Clients";
import Modules from "./views/modules";
import Users from "./views/users";
import UsersForm from "./views/users_form";
import E404 from "./views/e404";
import Roles from "./views/roles";
import Settings from "./views/settings";
import Logs from "./views/logs";
import Test from "./views/test";
import Reception from "./views/Reception";
import Bedrooms from "./views/Bedrooms";
import Categories from "./views/Categories";
import Products from "./views/Products";
import Tosell from "./views/Tosell";
import Expenses from "./views/Expenses";
import Prices from "./views/Prices";
import TypeServices from "./views/TypeServices";
import Boxs from "./views/Boxs";
import ReportAccommodations from "./views/ReportAccommodations ";
import ReportProducts from "./views/ReportProducts";
import ReportDashboard from "./views/ReportDashboard";
import Fbs from "./views/Fbs";

const routes = [
    {path: '/404', component: E404},
    {path: '/users', component: Users},
    {path: '/users/form', component: UsersForm},
    {path: '/users/form/:id', component: UsersForm},
    {path: '/clients', component: Clients},
    {path: '/modules', component: Modules},
    {path: '/roles', component: Roles},
    {path: '/settings', component: Settings},
    {path: '/logs', component: Logs},
    {path: '/test', component: Test},
    {path: '/reception', component: Reception},
    {path: '/bedrooms', component: Bedrooms},
    {path: '/categories', component: Categories},
    {path: '/products', component: Products},
    {path: '/tosell', component: Tosell},
    {path: '/expenses', component: Expenses},
    {path: '/prices', component: Prices},
    {path: '/type_services', component: TypeServices},
    {path: '/boxs', component: Boxs},
    {path: '/report_accommodations', component: ReportAccommodations},
    {path: '/report_products', component: ReportProducts},
    {path: '/report_dashboard', component: ReportDashboard},
    {path: '/fbs', component: Fbs},

];

export default routes;
