import React from 'react';
import {
    Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";

export default class ClientM extends _component {

    _state = {
        item: {
            id: 0,
            name: '',
            surname: '',
            document: '',
            birthdate: '',
            phone: '',
            gender: '0',
            email: '',
        },
        loading: false,
        error: null,
        errors: {},
        modal: false, // opciones del select
    };

    state = {...this._state};

    add = () => this.edit(0);

    edit = (id) => {
        this.setState({
            ...this._state,
            modal: true,
            loading: true,
        });
        Api.get('/clients/form', {id: id}, (rsp) => {
            if (rsp.ok) {

                this.setState({
                    loading: false,
                    item: rsp.item || this.state.item,
                });

            } else {
                this.setState({
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        Api.post('/clients/create', this.state.item, (rsp) => {
            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.toggleModal();
                if (typeof this.props.callback === 'function') this.props.callback({
                    ...this.state.item,
                    id: rsp.id
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg,
                    errors: rsp.errors
                });
            }
        });
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/clients/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...')
        });
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggleModal}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} cliente
                    </ModalHeader>
                    <ModalBody className="form-horizontal _frm">

                        <div className="alert alert-danger" hidden={!this.state.error}>
                            {this.state.error}
                        </div>

                        <FormGroup row>
                            <Col sm="6">
                                <Label>Nº documento</Label>
                                <Input name="document" value={item.document} onChange={this.changed}
                                       invalid={errors.document != null}/>
                                <FormFeedback>{errors.document}</FormFeedback>
                            </Col>
                            <Col sm="6">
                                <Label>Teléfono</Label>
                                <Input name="phone" value={item.phone} onChange={this.changed}
                                       invalid={errors.phone != null}/>
                                <FormFeedback>{errors.phone}</FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col sm="6">
                                <Label>Nombre</Label>
                                <Input name="name" value={item.name} onChange={this.changed}
                                       className="text-capitalize"
                                       invalid={errors.name != null}/>
                                <FormFeedback>{errors.name}</FormFeedback>
                            </Col>
                            <Col sm="6">
                                <Label>Apellido</Label>
                                <Input name="surname" value={item.surname} onChange={this.changed}
                                       className="text-capitalize"
                                       invalid={errors.surname != null}/>
                                <FormFeedback>{errors.surname}</FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col sm="4">
                                <Label>Correo electrónico</Label>
                                <Input name="email" value={item.email} onChange={this.changed}
                                       invalid={errors.email != null}/>
                                <FormFeedback>{errors.email}</FormFeedback>
                            </Col>
                            <Col sm="4">
                                <Label>Fecha de nacimiento</Label>
                                <Input name="birthdate" value={item.birthdate} onChange={this.changed}
                                       invalid={errors.birthdate != null} type="date"/>
                                <FormFeedback>{errors.birthdate}</FormFeedback>
                            </Col>
                            <Col sm="4">
                                <Label>Género</Label>
                                <Input name="gender" value={item.gender} onChange={this.changed}
                                       invalid={errors.gender != null} type="select">
                                    <option value="0">Elegir...</option>
                                    <option value="1">Hombre</option>
                                    <option value="2">Mujer</option>
                                </Input>
                                <FormFeedback>{errors.gender}</FormFeedback>
                            </Col>
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
