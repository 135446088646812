import React, {Component} from 'react';
import './notifications.scss';
import {Button} from "reactstrap";
import classnames from "classnames";
import {Api} from "../../inc/api";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

export default class Notifications extends Component {

  state = {
    loading: false,
    opened: false,
    items: [],
    id_removing: ''
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    Api.get('/notifications', {}, rsp => {
      if (rsp.ok) {
        this.setState({
          items: rsp.items
        });
      } else {

      }
      setTimeout(() => this.loadData(), 5000);
    }, false);
  }

  removeItem(id) {
    if (window.confirm('¿Seguro que quieres eliminar esta notificación?')) {
      this.setState({
        id_removing: id
      });
      Api.get('/notifications/remove', {id}, rsp => {
        if (rsp.ok) {
          toast.success('Eliminado correctamente');

          this.setState({
            items: this.state.items.filter(o => o.id != id),
            id_removing: ''
          });

        } else {
          toast.error('Se produjo un error');
        }
      }, 'Eliminando...');
    }
  }

  render() {
    return (
      <div className={classnames('notifications', {opened: this.state.opened})}>

        <div className="head"
             onClick={() => this.setState({opened: !this.state.opened})}>
          Notificaciones
          <i hidden={this.state.opened} className="fa fa-chevron-up"/>
          <i hidden={!this.state.opened} className="fa fa-chevron-down"/>
          <div className="count">{this.state.items.length}</div>
        </div>

        <div className="items">
          {this.state.items.map((o, i) => (
            <div className={"item clearfix type_" + o.type} key={i}>
              <div hidden={this.state.id_removing != o.id} className="removing">Eliminando...</div>
              <div hidden={this.state.id_removing == o.id}>
                <Link to={o.url} className="body">
                  <div dangerouslySetInnerHTML={{__html: o.body}}/>
                </Link>
                <Button onClick={() => this.removeItem(o.id)}>
                  <i className="fa fa-close"/>
                </Button>
              </div>
            </div>
          ))}
        </div>

      </div>
    );
  }
}
