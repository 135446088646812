import React, {Component} from 'react';
import {
    Button, Col, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import _component from "./_component";
import Alert from "../inc/alert";


export default class TypeServicesM extends _component {

    _state = {
        item: {
            id: 0,
            name: '',
            price: '',
            dots: '',
            pic: '',
            sort: '0',
        },
        loading: false,
        error: null,
        modal: false,
        errors: {},
        file_pic: null,
    };

    state = {...this._state};

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    add = () => {
        this.setState({
            ...this._state,
            loading: false,
            modal: true,
        });
    };

    edit = (id) => {
        this.setState({
            ...this._state,
            modal: true,
            loading: true,
        });
        Api.get('/type_services/dataForm', {id: id}, rsp => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    item: rsp.item ? rsp.item : this.state.item,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null,
            errors: {}
        });

        const data = new FormData();
        Object.keys(this.state.item).map(key => data.append(key, this.state.item[key]));
        data.append('file_pic', this.state.file_pic);

        Api.post('/type_services/create', data, (rsp) => {

            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.toggle();
                if (typeof this.props.callback === 'function') this.props.callback();
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg,
                    errors: rsp.errors
                });
            }

        });
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/type_services/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggle();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...');
        });
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}>
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} tipo de servicio
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row className="form-group">
                            <Col md="4">Nombre</Col>
                            <Col md="8">
                                <Input name="name" value={item.name} onChange={this.changed}
                                       invalid={errors.name != null}/>
                                <FormFeedback>{errors.name}</FormFeedback>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md="4">Imagen</Col>
                            <Col md="8">

                                {item.pic && (
                                    <a className="btn btn-outline-primary btn-sm mrg-b-10 bold" target="_blank"
                                       href={item.pic}>
                                        <i className="fa fa-file-picture-o"/> Ver imagen <i
                                        className="fa fa-angle-right"/>
                                    </a>
                                )}

                                <Dropzone
                                    className={classnames('_dropzone', {error: errors.file_pic != null})}
                                    activeClassName="active"
                                    onDrop={(files) => {
                                        console.log('onDrop', files);
                                        this.setState({
                                            ...this.state,
                                            file_pic: files.length > 0 ? files[0] : null
                                        });
                                    }}
                                    multiple={false}>
                                    {this.state.file_pic == null
                                        ? ''
                                        : <div className="bold mrg-b-10">{this.state.file_pic.name}</div>}
                                    <span className="btn btn-outline-dark">Examinar...</span>
                                </Dropzone>

                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md="4">Orden</Col>
                            <Col md="3">
                                <Input name="sort" value={item.sort} onChange={this.changed}
                                       invalid={errors.sort != null} type="number"/>
                                <FormFeedback>{errors.sort}</FormFeedback>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggle}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}