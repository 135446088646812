import React from 'react';
import {
    Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row
} from 'reactstrap';

import {Api} from "../../inc/api";
import _component from "../_component";
import Alert from "../../inc/alert";

export default class RecoverPassword extends _component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            item: {
                password: '',
                password2: '',
                tk: this.params.tk,
            },
            user: {},
            invalid: false,
            saved: false,
        };

    }

    componentDidMount() {

        this.setState({
            loading: true,
        });

        Api.get('/recover/checkTK', {tk: this.state.item.tk}, rsp => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    user: rsp.user,
                });
            } else {
                this.setState({
                    loading: false,
                    invalid: true,
                    error: rsp.msg,
                });
            }
        }, 'Verificando...');

    }

    save = (event) => {
        event.preventDefault();

        this.setState({
            loading: true,
            error: null,
        });

        const data = {...this.state.item};

        Api.get('/recover', data, rsp => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    error: null,
                    saved: true,
                });
            } else {
                this.setState({
                    loading: false,
                    error: rsp.msg,
                });
            }
        });

    };

    render() {
        const {item, saved, invalid} = this.state;

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="5">

                            <Card className="p-4" hidden={saved || invalid}>
                                <form onSubmit={this.save}>
                                    <CardBody>
                                        <h1>Recuperar contraseña</h1>
                                        <p className="text-muted">Ingrese sus datos a continuación</p>

                                        <div className="alert alert-danger mrg-t-10"
                                             hidden={!this.state.error}>
                                            {this.state.error}
                                        </div>

                                        <InputGroup className="mb-4 mrg-t-15">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-lock"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="password"
                                                   name="password"
                                                   value={item.password}
                                                   placeholder="Ingrese su contraseña"
                                                   onChange={this.changed}/>
                                        </InputGroup>

                                        <InputGroup className="mb-4">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-lock"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="password"
                                                   name="password2"
                                                   value={item.password2}
                                                   placeholder="Repita su contraseña"
                                                   onChange={this.changed}/>
                                        </InputGroup>

                                        <Button disabled={this.state.loading} color="primary">
                                            Cambiar contraseña
                                        </Button>

                                    </CardBody>
                                </form>
                            </Card>

                            <Card className="p-4" hidden={!saved}>

                                <div style={{textAlign: 'center', maxWidth: 260, margin: '100px auto'}}>

                                    <i className="fa fa-check-circle"
                                       style={{
                                           color: 'green',
                                           fontSize: 100,
                                       }}/>

                                    <div style={{fontSize: 30, color: 'green'}}>
                                        <b>¡Bien, </b>{this.state.user.name}!
                                    </div>

                                    <div style={{fontSize: 14, color: '#777'}}>
                                        Tu contraseña ha sido cambiada exitosamente
                                    </div>

                                </div>

                            </Card>

                            <Card className="p-4" hidden={!this.state.invalid}>

                                <div style={{textAlign: 'center', maxWidth: 260, margin: '100px auto'}}>

                                    <i className="fa fa-times-circle"
                                       style={{
                                           color: 'red',
                                           fontSize: 100,
                                       }}/>

                                    <div style={{fontSize: 30, color: 'red'}}>
                                        <b>¡O</b>ops!
                                    </div>

                                    <div style={{fontSize: 14, color: '#777'}}>
                                        {this.state.error}
                                    </div>

                                </div>

                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}
