import React from 'react';
import {
    Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import Util from "../inc/utils";
import {User} from "../inc/user";

const STATE = {
    item: {
        id: 0,
        type: '0',
        amount: '',
        reason: '',
    },
    report: 0,
    loading: false,
    error: null,
    errors: {},
    modal: false,
};

export default class ExpensesM extends _component {

    state = clone(STATE);

    add = (report = 0) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            report: report,
        });
    };

    edit = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/expenses/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                rsp.item.password = '';
                this.setState({
                    loading: false,
                    item: rsp.item,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        const item = {...this.state.item};
        const data = new FormData();
        Object.keys(item).map(key => data.append(key, item[key]));
        data.append('report', this.state.report);

        Api.post('/expenses/create', data, (rsp) => {

            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.toggleModal();
                if (typeof this.props.callback === 'function') this.props.callback({
                    ...this.state.item,
                    id: rsp.id
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg,
                    errors: rsp.errors
                });
            }
        });
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/expenses/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...');
        });
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}>
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} ingreso / egreso
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row className="form-group">
                            <Col md="4">Tipo</Col>
                            <Col md="8">
                                <Input name="type"
                                       value={item.type}
                                       onChange={this.changed}
                                       invalid={errors.type != null}
                                       type="select">
                                    <option value="0">Egreso</option>
                                    <option value="1">Ingreso</option>
                                </Input>
                                <FormFeedback>{errors.type}</FormFeedback>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md="4">Monto</Col>
                            <Col md="8">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">{User.stg.coin}</span>
                                    </div>
                                    <Input type="number"
                                           name="amount"
                                           value={item.amount}
                                           onChange={this.changed}
                                           invalid={errors.amount != null}/>
                                    <FormFeedback>{errors.amount}</FormFeedback>
                                </div>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md="4">Motivo</Col>
                            <Col md="8">
                                <Input type='textarea'
                                       rows={2}
                                       name="reason"
                                       value={item.reason}
                                       onChange={this.changed}
                                       invalid={errors.reason != null}/>
                                <FormFeedback>{errors.reason}</FormFeedback>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
