import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, FormFeedback, FormGroup, Input, Row} from "reactstrap";
import {Api} from "../../inc/api";

export default class InputSeach2 extends Component {
    state = {
        focus: false,
        endpoint: '',
        items: [],
        item: {}
    };

    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.loadData();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        this.loadData();
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.focus) {
                this.callback();
            }
        }
    }

    onFocus = () => {
        this.setState({
            item: this.props.item,
            focus: true,
        });
    };

    loadData = async () => {
        console.log("load data: ", this.props.item);
        await this.setState({item: this.props.item});
        let items = await this.query(this.state.item[this.props.inputName]);
        this.setState({items});
    };

    changed = async (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let items = this.state.items;

        this.setState({
            item: {
                ...this.state.item,
                id: 0,
                [name]: value,
            },
        });

        items = await this.query(value);

        this.setState({items});
    };

    query = (word) => {
        return new Promise(resolve => {
            Api.get(this.props.endpoint, {word: word}, rsp => {
                if (rsp.ok) {
                    resolve(rsp.items);
                } else {
                    resolve([]);
                }
            }, false);
        });
    };

    select = async (item) => {
        await this.setState({item});
        this.callback();
    };

    onBlur = async () => {
        setTimeout(() => {
            if (this.state.focus) {
                this.callback();
            }
        }, 200);
    };

    callback = () => {
        let rsp = new Object();
        rsp.item = this.state.item;
        rsp.items = this.state.items;
        this.props.callback(rsp);
        this.setState({focus: false});
    };

    refresh = () => {
        this.setState({focus: this.state.focus});
    };

    render() {
        var styleContainer = new Object();
        styleContainer.background = 'white';
        styleContainer.width = '100%';
        styleContainer.zIndex = '9';
        styleContainer.position = 'absolute';
        styleContainer.overflow = 'hidden';
        styleContainer.borderRadius = '5px';

        return <div ref={this.setWrapperRef}>
            <Input
                id='input'
                name={this.props.inputName}
                value={this.state.focus ? this.state.item[this.props.inputName] : this.props.initialValue}
                invalid={this.props.invalid != null}
                className="bg-white"
                autoComplete="off"
                onFocus={this.onFocus}
                onChange={this.changed}
                onBlur={this.onBlur}/>
            <FormFeedback>{this.props.invalid}</FormFeedback>
            {this.state.focus && (
                <div style={{'position': 'relative', 'background': '', 'height': '0px'}}>
                    <div className="border mt-2" style={styleContainer}>
                        {this.state.items.length == 0 && (
                            <li className="d-flex list-group-item-action p-2 pointer" onClick={this.callback}>
                                <span style={{'color': '#cfcfcf'}}>Sin resultados</span>
                            </li>
                        )}
                        {this.state.items.map((o, i) => (
                            <li key={i}
                                className="d-flex list-group-item-action p-2 pointer"
                                onClick={() => this.select(o)}>
                                <span>{o.name}</span>
                            </li>
                        ))}
                    </div>
                </div>
            )}
        </div>
    }
}

InputSeach2.propTypes = {
    initialValue: PropTypes.string,
    endpoint: PropTypes.string,
    inputName: PropTypes.string,
    item: PropTypes.object,
    invalid: PropTypes.string,
    callback: PropTypes.func,
};