import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {User} from "../../inc/user";

class DefaultFooter extends Component {
    render() {
        return (
            <React.Fragment>
                <span>
                    <Link to="/home">{User.stg.brand}</Link> &copy; 2019.
                </span>
                <span className="ml-auto float-right">
                    Powered by <a href="https://wabir.com/" target="_blank" rel="noopener noreferrer">Wabir</a>
                </span>
            </React.Fragment>
        );
    }
}

export default DefaultFooter;