import React, {Component} from 'react';
import {} from 'reactstrap';
import PropTypes from 'prop-types';

export default class ButtonState extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {props} = this;
        let active = this.props.active ? " active " : "";
        return (
            <button className={"btn btn-ghost-" + props.color + " mr-2 " + active}
                    onClick={() => props.function()}>
                <i className={"fa fa-" + props.icon}/> {props.name}
            </button>
        );
    }
}

ButtonState.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
    active: PropTypes.bool,
    function: PropTypes.func,
};
