import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';

import {User} from "../../inc/user";

import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import Notifications from "../notifications/notifications";

class DefaultLayout extends Component {

  state = {
    login: {},
    menu: User.menu
  };

  componentDidMount() {
    User.verifyListener = () => {
      this.setState({
        ...this.state,
        menu: User.menu
      });
      console.log('verifyListener.CALLED', User.id_role);
    };
    User.verify();
  }

  render() {
    return (
      <div className={"app" + (User.menu.collapsed ? ' _no_sidebar' : '')}>
        <AppHeader fixed>
          <DefaultHeader/>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader/>
            <AppSidebarForm/>
            <AppSidebarNav navConfig={this.state.menu} {...this.props} />
            <AppSidebarFooter/>
            <AppSidebarMinimizer/>
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid>
              <Switch>
                {routes.map((o, i) => <Route exact {...o} key={i}/>)}
                <Redirect from="/" exact={true} to={User.menu.home}/>
                <Redirect from="/" exact={false} to='/404'/>
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter/>
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
