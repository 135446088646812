import React from 'react';
import {
    Button, Card, CardBody, CardHeader, Input
} from 'reactstrap';
import Fils from "./widgets/fils";
import TypeServicesM from "./TypeServicesM";
import {User} from "../inc/user";
import Util from "../inc/utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class ReportProducts extends React.Component {

    state = {
        canEdit: User.can(this),
        results: '0',
        this_total_received: '0',
        view_total: false,
        total_received: '0',
    };

    loadData = (rsp) => {
        this.setState({
            ...this.state,
            results: !rsp.view_total ? 0 : this.state.results,
            view_total: rsp.view_total,
            this_total_received: rsp.this_total_received,
            total_received: rsp.total_received,
        });
    };

    changed = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value
        });
    };

    render() {
        return (
            <div>
                <Card className="_card">
                    <CardHeader>
                        <span className="text-uppercase bold">Reporte Productos</span>
                        <div className="card-header-actions">
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">
                        <Fils {...this.props}
                              ref={instance => this.fils = instance}
                              endpoint={this.props.match.url}
                              state={this.loadData}
                              fields={[
                                  {
                                      'type': 'range',
                                      'title': 'Filtro por fecha',
                                      'nameFrom': 'date_from',
                                      'nameTo': 'date_to',
                                  },
                                  {
                                      'type': 'select2',
                                      'title': 'Producto',
                                      'name': 'product',
                                      'endpoint': 'products'
                                  },
                                  {
                                      'type': 'select2',
                                      'title': 'Usuario',
                                      'name': 'user',
                                      'endpoint': 'users'
                                  },
                                  {
                                      'type': 'select2',
                                      'title': 'Cliente',
                                      'name': 'client',
                                      'endpoint': 'clients'
                                  },
                              ]}
                              cols={[
                                  {value: '#', width: '1%'},

                                  {value: 'Producto'},
                                  {value: 'Cant.', width: '1%'},
                                  {value: 'Precio', width: '1%'},
                                  {value: 'Inc.', width: '1%'},
                                  {value: 'Des.', width: '1%'},
                                  {value: 'Total', width: '1%'},
                                  {value: 'Usuario'},
                                  {value: 'Cliente'},
                                  {value: 'Fecha de creación', width: '1%'},
                              ]}
                              row={(o, i) => (
                                  <tr key={i}>
                                      <td>{o.id}</td>
                                      <td>{o.name}</td>
                                      <td>{o.quantity}</td>
                                      <td className="nowrap">{User.stg.coin} {Util.num(o.price, 2)}</td>
                                      {o.increment > 0
                                          ? <td className="nowrap">{User.stg.coin} {Util.num(o.increment, 2)}</td>
                                          : <td className="nowrap"></td>
                                      }
                                      {o.discount > 0
                                          ? <td className="nowrap">{User.stg.coin} {Util.num(o.discount, 2)}</td>
                                          : <td className="nowrap"></td>
                                      }
                                      <td className="nowrap">{User.stg.coin} {Util.num(o.charge, 2)}</td>
                                      <td>{o.u_name}</td>
                                      <td>{o.c_name}</td>
                                      <td className="nowrap">{o.date_created.datetime()}</td>
                                  </tr>
                              )}
                        />
                        <hr/>
                        <Row>
                            <Col md={8}></Col>
                            <Col md={4}>
                                <Card className="_card">
                                    <CardHeader>
                                        <span className="text-uppercase bold">Reportes</span>
                                        {this.state.view_total && (
                                            <div className="card-header-actions">
                                                <Input name="results"
                                                       value={this.state.results}
                                                       onChange={this.changed}
                                                       type="select">
                                                    <option value="0">Esta ventana</option>
                                                    <option value="1">Todo</option>
                                                </Input>
                                            </div>
                                        )}
                                    </CardHeader>
                                    <CardBody>
                                        <li className="d-flex justify-content-between align-items-center bold">
                                            Total
                                            {this.state.results == 0
                                                ?
                                                <span
                                                    className={"badge-pill bold " + (this.state.this_total_received < 0 ? "text-danger" : " text-success")}>
                                                {User.stg.coin} {Util.num(this.state.this_total_received, 2)}
                                            </span>
                                                :
                                                <span
                                                    className={"badge-pill bold " + (this.state.total_received < 0 ? "text-danger" : " text-success")}>
                                                {User.stg.coin} {Util.num(this.state.total_received, 2)}
                                            </span>
                                            }
                                        </li>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <TypeServicesM ref={instance => this.modal = instance}
                               callback={() => this.fils.loadData()}/>

            </div>)
    }
}