import React from 'react';
import {
    Button, Col, FormFeedback, FormGroup, Input, Label, Modal,
    ModalBody, ModalFooter, ModalHeader, Row, Table, Badge
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import clone from "clone-deep";
import {User} from "../inc/user";
import Util from "../inc/utils";
import AsyncSelect from "react-select/lib/Async";
import CompanionsM from "./CompanionsM";
import AddTimeM from "./AddTimeM";
import InpurSearch from "./widgets/input_search";

const STATE = {
    item: {
        plate: '',
    },
    prices: [],
    bedroom: {},
    client: {},
    rate: {
        increment: '0',
        discount: '0',
        total: '0',
        cash: '',
        remaining: '0',
        charge: '0',
    },
    companions: [],
    loading: false,
    error: null,
    errors: {},
    modal: false,
};

const CLIENT = {
    id: 0,
    name: '',
    surname: '',
    document: '',
    birthdate: '',
    phone: '',
    gender: '0',
    email: '',
};

export default class BedroomsM extends _component {

    state = clone(STATE);

    add = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/reception/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                let total = (Util.num(rsp.bedroom.price) + Util.num(rsp.bedroom.increment)) - Util.num(rsp.bedroom.discount);
                this.setState({
                    ...this.state,
                    loading: false,
                    prices: rsp.prices,
                    bedroom: rsp.bedroom,
                    client: rsp.client,
                    rate: {
                        ...this.state.rate,
                        hours: rsp.bedroom.hours,
                        price: rsp.bedroom.price,
                        charge: rsp.bedroom.price,
                        subtotal: rsp.bedroom.price,
                        total: total
                    },
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    changedClient = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            client: {
                ...this.state.client,
                [name]: value
            }
        });
    };

    changedRate = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const bedroom = this.state.bedroom;
        const rate = this.state.rate;

        let hours = Util.num(rate.hours);
        let price = Util.num(rate.price);
        let charge = Util.num(rate.charge);
        let increment = Util.num(rate.increment);
        let discount = Util.num(rate.discount);

        if (name === "increment" || name === "discount" || name === "charge" || name === "hours" || name === "defined_price") {
            if (name === "increment") {
                increment = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                charge = (price + increment) >= 0 ? (price + increment) : 0;
                discount = 0;
            } else if (name === "discount") {
                discount = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                if (discount > price) {
                    discount = price;
                }
                charge = (price - discount) >= 0 ? (price - discount) : 0;
                increment = 0;
            } else if (name === "charge") {
                charge = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                increment = (charge - price);
                if (increment < 0) {
                    discount = (increment * -1);
                    increment = 0;
                } else {
                    discount = 0;
                }
            } else if (name === 'hours') {
                hours = value != '' ? value > 0 ? Util.num(value, 0) : 0 : '';

                if (hours > Util.num(bedroom.hours)) {
                    let diff = hours - bedroom.hours;
                    price = Util.num(bedroom.price) + Util.num(diff * bedroom.price_hour);

                    if (discount > 0 && (price < discount)) {
                        discount = price;
                    }

                    charge = ((Util.num(price) + Util.num(increment)) - Util.num(discount));
                } else {
                    if (hours > 0) {
                        price = bedroom.price;

                        if (discount > 0 && (price < discount)) {
                            discount = price;
                        }

                        charge = ((Util.num(price) + Util.num(increment)) - Util.num(discount));
                    } else {
                        price = 0;
                        charge = 0;
                    }
                }
            } else if (name === 'defined_price' && value >= 0) {
                let defined_price = this.state.prices[value];
                hours = defined_price.hours;
                price = defined_price.price;
                increment = 0;
                discount = 0;
                charge = ((Util.num(price) + Util.num(increment)) - Util.num(discount));
            }

            let total = (Util.num(price) + Util.num(increment)) - Util.num(discount);

            this.setState({
                rate: {
                    ...this.state.rate,
                    hours: hours,
                    price: price,
                    charge: charge,
                    increment: increment,
                    discount: discount,
                    subtotal: price,
                    total: total,
                },
                error: '',
            });
        } else {
            this.setState({
                rate: {
                    ...this.state.rate,
                    [name]: value,
                },
                error: '',
            });
        }
    };

    removeCompanion = (index) => {
        this.state.companions.splice(index, 1);
        this.setState({});
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        const item = {...this.state.item};
        const client = {...this.state.client};
        const rate = {...this.state.rate};
        const data = new FormData();
        let remaining = (Util.num(rate.total) - Util.num(rate.cash));
        data.append('bedroom', this.state.bedroom.id);
        item.companions = JSON.stringify(this.state.companions);
        Object.keys(item).map(key => data.append(key, item[key]));
        Object.keys(client).map(key => data.append(key, client[key]));
        Object.keys(rate).map((key) => {
            let value = rate[key];

            switch (key) {
                case 'remaining':
                    value = remaining;
                    break;
                case 'cash':
                    value = value >= rate.total ? rate.total : value;
                    break;
                default:
                    value = value == '' ? 0 : value;
            }

            data.append(key, value)
        });

        if (remaining > 0) {
            this.setState({
                ...this.state,
                loading: false
            });

            let amount = '<span class="text-danger bold">' + User.stg.coin + ' ' + Util.num(remaining, 2) + '</span>';
            let message = 'Falta cobrar ' + amount + ', se le cobrara al desocupar la habitación.';

            Alert.confirm(message, () => {
                Alert.loading();
                Api.post('/reception/create', data, (rsp) => {
                    Alert.loading(false);
                    if (rsp.ok) {
                        toast.success('Guardado correctamente');
                        this.toggleModal();
                        if (typeof this.props.callback === 'function') this.props.callback();
                    } else {
                        Alert.error(rsp.msg);
                        this.setState({
                            ...this.state,
                            loading: false,
                            errors: rsp.errors
                        });
                    }
                }, 'Guardando...');
            });
        } else {
            Api.post('/reception/create', data, (rsp) => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Guardado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                    this.setState({
                        ...this.state,
                        loading: false,
                        errors: rsp.errors
                    });
                }
            }, 'Guardando...');
        }
    };

    callbackDocument = async (o) => {
        console.log('callbackDocument: ', o);
        if (o.stateItem) {
            await this.setState({
                ...this.state,
                client: o.item,
            });
            this.setState({
                client: {
                    ...this.state.client,
                    document: o.inputValue
                }
            });
        } else {
            await this.setState({
                ...this.state,
                client: CLIENT,
            });
            this.setState({
                client: {
                    ...this.state.client,
                    document: o.inputValue
                }
            });
        }
    };

    render() {
        const {prices, bedroom, client, item, companions, rate, errors} = this.state;
        const turned = (rate.cash - rate.charge);
        const colorBlue = '#20a8d8';
        const classBetween = 'btn-block d-flex justify-content-between align-items-center';
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggleModal}>
                        {bedroom.id > 0 ? 'Ocupar' : 'Ocupar'} habitación
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <span className="bold">Información de la habitación:</span>
                                </FormGroup>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Nombre:</Label>
                                            <Input disabled value={bedroom.name}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Precio</Label>
                                            <Input disabled value={User.stg.coin + ' ' + Util.num(bedroom.price, 2)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Horas</Label>
                                            <Input disabled value={bedroom.hours}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Pre. x Hor:</Label>
                                            <Input disabled
                                                   value={User.stg.coin + ' ' + Util.num(bedroom.price_hour, 2)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Detalles</Label>
                                            <Input type='textarea'
                                                   disabled
                                                   rows={1}
                                                   value={bedroom.detail}/>
                                        </FormGroup>
                                    </Col>

                                    {typeof bedroom.categories !== 'undefined' && (
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Categorias</Label>
                                                <div>
                                                    {bedroom.categories.map((o, i) => (
                                                        <span className="mr-1">
                                                            <Badge color="light" className="mr-1">{o.category}</Badge>
                                                            {o.value}
                                                        </span>
                                                    ))}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    )}

                                    {typeof bedroom.type_services !== 'undefined' && (
                                        <Col md={6} className="mb-3">
                                            <FormGroup>
                                                <Label>Tipos de servicios</Label>
                                                <div>
                                                    {bedroom.type_services.map((o, i) => (
                                                        <Badge color="light" className="mr-1">{o.name}</Badge>
                                                    ))}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            <Col md={6}>
                                <div className="card">
                                    <div className="card-header">
                                        <span className="bold">Datos del cliente</span>
                                    </div>
                                    <div className="card-body p-2">
                                        <Row>
                                            <Col md="6">
                                                <p>Documento:</p>
                                                <InpurSearch
                                                    endpoint='/reception/search'
                                                    inputValue={client.document}
                                                    active='document'
                                                    title='document'
                                                    item={client}
                                                    invalid={errors.document}
                                                    callback={this.callbackDocument}/>
                                            </Col>

                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>Teléfono</Label>
                                                    <Input type="number"
                                                           name="phone" value={client.phone}
                                                           onChange={this.changedClient}
                                                           invalid={errors.phone != null}/>
                                                    <FormFeedback>{errors.phone}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>Nombre</Label>
                                                    <Input name="name" value={client.name}
                                                           className="text-capitalize"
                                                           onChange={this.changedClient}
                                                           invalid={errors.name != null}/>
                                                    <FormFeedback>{errors.name}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>Apellido</Label>
                                                    <Input name="surname" value={client.surname}
                                                           className="text-capitalize"
                                                           onChange={this.changedClient}
                                                           invalid={errors.surname != null}/>
                                                    <FormFeedback>{errors.surname}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>Correo electrónico</Label>
                                                    <Input name="email" value={client.email}
                                                           onChange={this.changedClient}
                                                           invalid={errors.email != null}/>
                                                    <FormFeedback>{errors.email}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>Género</Label>
                                                    <Input name="gender" value={client.gender}
                                                           onChange={this.changedClient}
                                                           invalid={errors.gender != null} type="select">
                                                        <option value="0">Elegir...</option>
                                                        <option value="1">Hombre</option>
                                                        <option value="2">Mujer</option>
                                                    </Input>
                                                    <FormFeedback>{errors.gender}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col md="12">
                                                <FormGroup>
                                                    <Label>Fecha de nacimiento</Label>
                                                    <div className="input-group">
                                                        <span className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fa fa-calendar"></i>
                                                            </span>
                                                        </span>
                                                        <Input name="birthdate"
                                                               value={client.birthdate}
                                                               onChange={this.changedClient}
                                                               invalid={errors.birthdate != null}
                                                               type="date"/>
                                                    </div>
                                                    <FormFeedback>{errors.birthdate}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col md="12">
                                                <FormGroup>
                                                    <Label>Placa</Label>
                                                    <Input name="plate" value={item.plate}
                                                           onChange={this.changed}
                                                           invalid={errors.plate != null}/>
                                                    <FormFeedback>{errors.plate}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12} className="mt-3">
                                                <div
                                                    className="btn-block d-flex justify-content-between align-items-center">
                                                <span className="bold">
                                                    Acompañantes
                                                </span>
                                                    <button className="btn btn-outline-dark"
                                                            onClick={() => this.modal.add(this.state.companions)}>
                                                        <span className="fa fa-users mr-2"/>
                                                        Agregar
                                                    </button>
                                                </div>
                                            </Col>

                                            {companions.length > 0 && (
                                                <Col md={12} className="mt-3">
                                                    <Table bordered responsive size="sm">
                                                        <thead>
                                                        <tr>
                                                            <th>Nombre</th>
                                                            <th>Apellido</th>
                                                            <th width="1%"></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {companions.map((o, i) => (
                                                            <tr>
                                                                <td>{o.name}</td>
                                                                <td>{o.surname}</td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        <Button color="danger" size="sm"
                                                                                onClick={() => this.removeCompanion(i)}>
                                                                            <i className="fa fa-times"/>
                                                                        </Button>
                                                                        <Button color="primary" size="sm"
                                                                                onClick={() => this.modal.add(o, this.state.companions)}>
                                                                            <i className="fa fa-pencil"/>
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={12}>
                                        <div className="card">
                                            <div className="card-header">
                                                <span className="bold">Datos del alojamiento</span>
                                            </div>
                                            <div className="card-body p-2">
                                                <Row>
                                                    {prices.length > 0 && (
                                                        <Col sm="12">
                                                            <FormGroup>
                                                                <Label>Precios definidos</Label>
                                                                <Input name="defined_price"
                                                                       onChange={this.changedRate}
                                                                       type="select">
                                                                    <option selected value='select'>Elegir...</option>
                                                                    {prices.map((o, i) => (
                                                                        <option value={i}>
                                                                            {o.hours + ' Horas ' + User.stg.coin + ' ' + Util.num(o.price, 2)}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    <hr/>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label>Horas</Label>
                                                            <Input name="hours"
                                                                   className="form-control"
                                                                   type="number"
                                                                   value={rate.hours}
                                                                   onChange={this.changedRate}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={8}>
                                                        <FormGroup>
                                                            <Label>Precio</Label>
                                                            <Input className="form-control"
                                                                   disabled
                                                                   value={User.stg.coin + ' ' + Util.num(rate.price, 2)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {bedroom.edit_price && (
                                                    <Row>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label>Incremento</Label>
                                                                <Input name="increment"
                                                                       className="form-control"
                                                                       type="number"
                                                                       value={rate.increment}
                                                                       onChange={this.changedRate}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label>Descuento</Label>
                                                                <Input name="discount"
                                                                       className="form-control"
                                                                       type="number"
                                                                       value={rate.discount}
                                                                       onChange={this.changedRate}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Nuevo precio</Label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                    <span
                                                        className="input-group-text">{User.stg.coin}</span>
                                                                    </div>
                                                                    <Input name="charge"
                                                                           className="form-control"
                                                                           type="number"
                                                                           value={rate.charge}
                                                                           onChange={this.changedRate}/>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>)}
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={12}>
                                        <div className="card">
                                            <div className="card-header inputSimpleText">
                                                <b>Facturación</b>
                                            </div>
                                            <div className="card-body p-2">
                                                <div className={classBetween}>
                                                <span style={{}}>
                                                    Subtotal
                                                </span>
                                                    <span style={{
                                                        'color': colorBlue
                                                    }}>
                                                    {User.stg.coin + ' ' + Util.num(rate.subtotal, 2)}
                                                </span>
                                                </div>

                                                {rate.increment > 0 && (
                                                    <div className={classBetween}>
                                                    <span style={{}}>
                                                        Incremento
                                                    </span>
                                                        <span style={{
                                                            'color': colorBlue
                                                        }}>
                                                        {User.stg.coin + ' ' + Util.num(rate.increment, 2)}
                                                    </span>
                                                    </div>
                                                )}

                                                {rate.discount > 0 && (
                                                    <div className={classBetween}>
                                                <span style={{}}>
                                                    Descuento
                                                </span>
                                                        <span style={{
                                                            'color': colorBlue
                                                        }}>
                                                    {User.stg.coin + ' ' + Util.num(rate.discount, 2)}
                                                </span>
                                                    </div>
                                                )}

                                                <div className={classBetween}>
                                                <span className="bold">
                                                    Total
                                                </span>
                                                    <span className="bold text-primary"
                                                          style={{'cursor': 'pointer'}}
                                                          onClick={() => {
                                                              this.setState({
                                                                  rate: {
                                                                      ...this.state.rate,
                                                                      cash: this.state.rate.charge
                                                                  }
                                                              });
                                                          }}>
                                                    {User.stg.coin + ' ' + Util.num(rate.total, 2)}
                                                </span>
                                                </div>
                                                <hr/>
                                                <div className={classBetween}>
                                                    <FormGroup>
                                                        <Label>Efectivo</Label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span
                                                                    className="input-group-text">{User.stg.coin}</span>
                                                            </div>
                                                            <Input type="number"
                                                                   name="cash"
                                                                   value={rate.cash}
                                                                   onChange={this.changedRate}/>
                                                        </div>
                                                    </FormGroup>
                                                    <div className="m-2"/>
                                                    <FormGroup>
                                                        <Label className={turned < 0 ? 'text-danger' : 'text-success'}>
                                                            {turned < 0 ? 'Falta cobrar: ' : 'Vuelto: '}
                                                        </Label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                            <span
                                                                className={turned < 0 ? 'input-group-text text-danger' : 'input-group-text text-success'}>
                                                                {User.stg.coin}
                                                            </span>
                                                            </div>
                                                            <Input
                                                                className={turned < 0 ? 'text-danger' : 'text-success'}
                                                                value={Util.num(turned < 0 ? (turned * -1) : turned, 2)}/>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Ocupar
                        </Button>
                    </ModalFooter>

                    <CompanionsM ref={ins => this.modal = ins}
                                 callback={(companions) => {
                                     this.setState({
                                         ...this.state,
                                         companions: companions
                                     });
                                 }}/>
                </fieldset>
            </Modal>
        );
    }
}
