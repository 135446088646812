import React from 'react';
import {
    Button,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    UncontrolledDropdown
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import {User} from "../inc/user";
import Util from "../inc/utils";
import AsyncSelect from "react-select/lib/Async";
import ShopM from "./ShopM";

const STATE = {
    item: {
        id: 0,
        products: [],
    },
    subtotal: 0,
    increment: 0,
    discount: 0,
    total: 0,
    cash: 0,
    word: '',
    products: [],
    loading: false,
    error: null,
    errors: {},
    modal: false,
}

export default class TosellM extends _component {

    state = clone(STATE);

    add = (report) => {
        this.setState({
            ...clone(STATE),
            item: {
                ...this.item,
                id: report,
                products: [],
            },
            modal: true,
            loading: true,
        });
        Api.get('/products', (rsp) => {
            if (rsp.ok) {
                this.setState({
                    ...this.state,
                    loading: false,
                    products: rsp.items,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    changed = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value
        });

        if (name == "word") {
            this.search(value);
        }
    };

    search = (word) => {
        Api.get('/products', {word: word}, (rsp) => {
            if (rsp.ok) {
                this.setState({
                    ...this.state,
                    products: rsp.items,
                });
            } else {
                this.setState({
                    ...this.state,
                    error: rsp.msg
                });
            }
        }, false);
    };

    reload = (products) => {
        let subtotal = 0;
        let increment = 0;
        let discount = 0;
        let total = 0;

        products.map((o, i) => {
            subtotal += (o.quantity * o.price);
            increment += o.increment;
            discount += o.discount;
        });

        if ((discount - increment) >= 0) {
            discount = (discount - increment);
            total = (subtotal - discount);
            increment = 0;
        } else {
            increment = (increment - discount);
            total = (subtotal + increment);
            discount = 0;
        }

        this.setState({
            item: {
                ...this.state.item,
                products: products
            },
            subtotal: subtotal,
            increment: increment,
            discount: discount,
            total: total,
        });
    };

    removeItem = (index) => {
        this.state.item.products.splice(index, 1);
        this.setState({});
        this.reload(this.state.item.products);
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        const item = {...this.state.item};
        const state = {...this.state};
        const data = new FormData();

        let remaining = (state.total - state.cash);
        item.products = JSON.stringify(item.products);
        Object.keys(item).map(key => data.append(key, item[key]));
        data.append('subtotal', state.subtotal);
        data.append('increment', state.increment);
        data.append('discount', state.discount);
        data.append('total', state.total);
        data.append('cash', state.cash > state.total ? state.total : state.cash);
        data.append('remaining', remaining > 0 ? remaining : 0);

        if (remaining > 0) {
            this.setState({
                ...this.state,
                loading: false
            });

            let amount = '<span class="text-danger bold">' + User.stg.coin + ' ' + Util.num(remaining, 2) + '</span>';
            let message = 'Falta cobrar ' + amount + ', se le cobrara al desocupar la habitación.';

            Alert.confirm(message, () => {
                Alert.loading();
                Api.post('/reception/create_order', data, (rsp) => {
                    Alert.loading(false);
                    if (rsp.ok) {
                        toast.success('Venta generada correctamente');
                        this.toggleModal();
                        if (typeof this.props.callback === 'function') this.props.callback();
                    } else {
                        Alert.error(rsp.msg);
                    }
                }, 'Generando venta...');
            });
        } else {
            Api.post('/reception/create_order', data, (rsp) => {
                if (rsp.ok) {
                    toast.success('Venta generada correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            });
        }
    };

    render() {
        const {item, word, products} = this.state;
        const td = {
            "verticalAlign": "middle",
            'padding': '0px 10px 0px 10px'
        };
        const classBetween = 'btn-block d-flex justify-content-between align-items-center';
        const turned = (this.state.cash - this.state.total);
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        Generar venta
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={6} className="mt-2">
                                <FormGroup>
                                    <span className="lead bold">Lista de productos:</span>
                                </FormGroup>
                                <FormGroup>
                                    <div className="input-group">
                                        <Input className="form-control"
                                               name="word"
                                               value={word}
                                               placeholder="Buscar productos..."
                                               onChange={this.changed}/>
                                        <span className="input-group-append">
                                            <button className="btn btn-primary" type="button"
                                                    onClick={() => this.search(word)}>
                                                <i className="fa fa-search"/>
                                            </button>
                                        </span>
                                    </div>
                                </FormGroup>
                            </Col>
                            {products.length > 0 ?
                                <Col md={12}>
                                    <Table bordered responsive size="sm">
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Detalle</th>
                                            <th>Stock</th>
                                            <th>Precio</th>
                                            <th width="1%"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            products.map((o, i) => (
                                                <tr>
                                                    <td style={td}>{o.name}</td>
                                                    <td style={td}>{o.detail}</td>
                                                    <td style={td}>{o.stock}</td>
                                                    <td style={td}>{User.stg.coin + ' ' + Util.num(o.price, 2)} </td>
                                                    <td>
                                                        <Button color="primary" size="sm"
                                                                onClick={() => this.shop.add(o, item.products)}>
                                                            <i className="fa fa-shopping-cart"/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                                : <Col md={12}>
                                    <div className="alert alert-warning">No se encontraron resultados
                                        para {this.state.word}.
                                    </div>
                                </Col>
                            }
                            {item.products.length > 0 && (
                                <Col md={12}>
                                    <FormGroup>
                                        <Label className="lead bold">Productos en la canasta:</Label>
                                    </FormGroup>
                                    <Table bordered size="sm">
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Cantidad</th>
                                            <th>Precio</th>
                                            <th>Incremento</th>
                                            <th>Descuento</th>
                                            <th>Total</th>
                                            <th width="1%"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            item.products.map((o, i) => (
                                                <tr>
                                                    <td style={td}>{o.name}</td>
                                                    <td style={td}>{o.quantity}</td>
                                                    <td style={td}>{User.stg.coin + ' ' + Util.num(o.price, 2)}</td>
                                                    <td style={td}>
                                                        {o.increment > 0 ? User.stg.coin + ' ' + Util.num(o.increment, 2) : ''}
                                                    </td>
                                                    <td style={td}>
                                                        {o.discount > 0 ? User.stg.coin + ' ' + Util.num(o.discount, 2) : ''}
                                                    </td>
                                                    <td style={td}>{User.stg.coin + ' ' + Util.num(o.charge, 2)}</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <Button color="danger" size="sm"
                                                                    onClick={() => this.removeItem(i)}>
                                                                <i className="fa fa-times"/>
                                                            </Button>
                                                            <Button color="primary" size="sm"
                                                                    onClick={() => this.shop.add(o, item.products)}>
                                                                <i className="fa fa-pencil"/>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            )}

                            <Col md={6}></Col>

                            {item.products.length > 0 && (
                                <Col md={6}>
                                    <div className="card">
                                        <div className="card-header inputSimpleText">
                                            <b>Facturación</b>
                                        </div>
                                        <div className="card-body p-2">
                                            <div className={classBetween}>
                                                <span style={{}}>
                                                    Subtotal
                                                </span>
                                                <span className="text-primary">
                                                    {User.stg.coin + ' ' + Util.num(this.state.subtotal, 2)}
                                                </span>
                                            </div>

                                            {this.state.increment > 0 && (
                                                <div className={classBetween}>
                                                    <span style={{}}>
                                                        Incremento
                                                    </span>
                                                    <span className="text-primary">
                                                        {User.stg.coin + ' ' + Util.num(this.state.increment, 2)}
                                                    </span>
                                                </div>
                                            )}

                                            {this.state.discount > 0 && (
                                                <div className={classBetween}>
                                                <span>
                                                    Descuento
                                                </span>
                                                    <span className="bold text-primary">
                                                    {User.stg.coin + ' ' + Util.num(this.state.discount, 2)}
                                                </span>
                                                </div>
                                            )}

                                            <div className={classBetween}>
                                                <span className="bold">
                                                    Total
                                                </span>
                                                <span className="bold text-primary pointer"
                                                      onClick={() => {
                                                          this.setState({cash: Util.num(this.state.total, 2)})
                                                      }}>
                                                    {User.stg.coin + ' ' + Util.num(this.state.total, 2)}
                                                </span>
                                            </div>
                                            <hr/>
                                            <div className={classBetween}>
                                                <FormGroup>
                                                    <Label>Efectivo</Label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">{User.stg.coin}</span>
                                                        </div>
                                                        <Input name="cash"
                                                               value={this.state.cash}
                                                               onChange={this.changed}/>
                                                    </div>
                                                </FormGroup>
                                                <div className="m-2"/>
                                                <FormGroup>
                                                    <Label className={turned < 0 ? 'text-danger' : 'text-success'}>
                                                        {turned < 0 ? 'Falta cobrar: ' : 'Vuelto: '}
                                                    </Label>

                                                    <Input className={turned < 0 ? 'text-danger' : 'text-success'}
                                                           value={User.stg.coin + ' ' + Util.num(turned < 0 ? (turned * -1) : turned, 2)}/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        {item.products.length > 0 && (
                            <Button color="primary" onClick={this.save}>
                                <i className="fa fa-check"/> Vender
                            </Button>
                        )}
                    </ModalFooter>
                </fieldset>
                <ShopM ref={ins => this.shop = ins}
                       callback={(products) => this.reload(products)}/>
            </Modal>
        );
    }
}
