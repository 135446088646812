import React from 'react';
import Swal from 'sweetalert2'

export default class Alert {

    static loading(message) {

        if (typeof message === 'boolean' && !message) {

            Swal.close();

        } else {
            Swal({
                html: message || 'Espere un momento...',
                allowOutsideClick: () => false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
                onClose: () => {

                }
            }).then((result) => {
                if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.timer
                ) {
                    console.log('I was closed by the timer')
                }
            })
        }

    }

    static error(message, callback) {
        Swal({
            type: 'error',
            title: 'Se produjo un error',
            text: message || 'Por favor inténtalo de nuevo',
            footer: '<em>Si el error persiste, contacte con soporte.</em>',
            confirmButtonText: 'Aceptar'
        }).then(result => {
            if (typeof callback === 'function') {
                callback(result);
            }
        })
    }

    /**
     * Dialogo de confirmacion, los parametros pueden ser flexibles, de derecha a izquierda, excepto el callback
     *
     * EJEMPLOS DE USO:
     * Alert.confirm('Mensaje', 'Si, eliminar', () => {});
     * Alert.confirm('Mensaje', () => {});
     * Alert.confirm(() => {});
     */
    static confirm(message, confirmButton, callback) {

        let opts = {
            title: null,
            message: '<b>¿Estás seguro?</b> Esta acción es irreversible',
            confirmButton: '<i class="fa fa-check"></i> Confirmar',
            cancelButton: 'Cancelar',
            callback: () => {
            }
        };

        if (typeof message === 'object') {
            console.log('es un objeto');

            opts = {...opts, ...message};

        } else if (typeof message === 'function') {
            opts.callback = message;

        } else if (typeof confirmButton === 'function') {
            opts.message = message;
            opts.callback = confirmButton;

        } else {
            opts.message = message;
            opts.confirmButton = confirmButton;
            opts.callback = callback;
        }

        Swal({
            title: opts.title,
            html: opts.message,
            showCancelButton: true,
            confirmButtonText: opts.confirmButton,
            cancelButtonText: opts.cancelButton,
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                opts.callback();
            }
        })
    }

    static message(message) {
        Swal.fire({
            html: message,
        });
    }
}