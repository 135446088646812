import React from 'react';
import {BarLoader} from "react-spinners";
import {Api} from "../inc/api";
import {Alert} from "reactstrap";
import {toast} from "react-toastify";

export default class UsersForm extends React.Component {

    state = {
        loading: false,
        user: {
            id: 0,
            id_role: 0,
            id_management: 0,
            name: '',
            surname: '',
            username: '',
            password: ''
        },
        roles: [],
        managements: [],
        error: false,
        error_msg: ''
    };

    componentDidMount() {

        this.setState({loading: true});

        Api.get('/users/dataForm', {id: this.props.match.params.id}, (rsp) => {
            console.log('rsp', rsp);
            if (rsp.ok) {
                console.log('roles', rsp.roles);
                //rsp.user.password = '';
                this.setState({
                    loading: false,
                    user: rsp.user ? {
                        ...rsp.user,
                        password: ''
                    } : this.state.user,
                    roles: rsp.roles,
                    managements: rsp.managements
                });
            } else {
                this.setState({loading: false});
            }
        });

    }

    handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                [name]: value
            }
        });

        console.log('name', name, 'value', value);
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            loading: true,
            error: false
        });

        Api.post('/users/create', this.state.user, (rsp) => {
            this.setState({
                ...this.state,
                loading: false,
                error: !rsp.ok,
                error_msg: rsp.msg
            });

            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.back();
            }

        });

        console.log('state.user', this.state.user);
    };

    back = () => {
        this.props.history.push('/users');
        //this.props.history.goBack();
    };

    render() {
        return (
            <form className="card medi form-horizontal" onSubmit={this.save}>

                <fieldset disabled={this.state.loading}>
                    <div className="card-header bold text-uppercase">
                        {this.state.id > 0 ? 'Editar' : 'Agregar'} usuario
                    </div>
                    <div hidden={!this.state.loading} style={{height: 2, marginTop: '-2px', background: 'white'}}>
                        <BarLoader width={-1}/>
                    </div>
                    <div className="card-body form-horizontal">

                        <Alert color="danger" isOpen={this.state.error} fade={true}>
                            {this.state.error_msg}
                        </Alert>

                        <div className="row form-group">
                            <div className="col-md-3">
                                <label>Perfil</label>
                            </div>
                            <div className="col-md-9">
                                <select className="form-control" name="id_role" value={this.state.user.id_role}
                                        onChange={this.handleInputChange}>
                                    <option value="">Elegir...</option>
                                    {this.state.roles.map((o, i) => <option value={o.id} key={i}>{o.name}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-md-3">
                                <label>Gerencia</label>
                            </div>
                            <div className="col-md-9">
                                <select className="form-control" name="id_management" value={this.state.user.id_management}
                                        onChange={this.handleInputChange}>
                                    <option value="">Elegir...</option>
                                    {this.state.managements.map((o, i) => <option value={o.id} key={i}>{o.name}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-md-3">
                                <label>Nombre</label>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control" name="name" value={this.state.user.name}
                                       onChange={this.handleInputChange}/>
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-md-3">
                                <label>Apellido</label>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control" name="surname" value={this.state.user.surname}
                                       onChange={this.handleInputChange}/>
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-md-3">
                                <label>Usuario</label>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control" name="username" value={this.state.user.username}
                                       onChange={this.handleInputChange}/>
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-md-3">
                                <label>Contraseña</label>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control" name="password" value={this.state.user.password}
                                       onChange={this.handleInputChange} type="password"/>
                            </div>
                        </div>

                    </div>
                    <div className="card-footer">
                        <span className="bold text-uppercase btn btn-outline-secondary" onClick={this.back}>
                            Cancelar
                        </span>
                        {' '}
                        <button className="bold text-uppercase btn btn-primary">
                            <i className="fa fa-check"/> Guardar
                        </button>
                    </div>
                </fieldset>

            </form>
        )
    }
}