import React from 'react';
import {
    Badge,
    Button,
    Card, CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from 'reactstrap';
import {Api} from "../inc/api";
import _component from "./_component";
import clone from "clone-deep";
import Util from "../inc/utils";
import {User} from "../inc/user";
import ItemCollapseReport from "./widgets/item_collapse_report";

const STATE = {
    item: {
        id: 0,
        plate: '',
        date_entry: '',
        date_departure: '',
        bedroom: {},
        user: {},
        client: {},
        companions: {},
        report_items: [],
        orders: [],
        expenses: [],
        money_income: '0',
        money_expenditures: '0',
        money_received: '0'
    },
    loading: false,
    error: null,
    errors: {},
    modal: false,
};

export default class ReportAccommodationsM extends _component {

    state = clone(STATE);

    add = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/report_accommodations/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                rsp.item.password = '';
                this.setState({
                    loading: false,
                    item: rsp.item,
                });
                console.log(rsp);
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    render() {
        const {item} = this.state;
        const {bedroom, client, companions, report_items, orders, expenses} = this.state.item;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggleModal}>
                        Detalle
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Ingreso:</Label>
                                    <Input value={item.date_entry.datetime()} disabled/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Salida:</Label>
                                    {item.date_departure != null && (
                                        <Input value={item.date_departure.datetime()} disabled/>
                                    )}

                                    {item.date_departure == null && (
                                        <Input value="Actualmente ocupada" disabled/>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <span className="bold">Información de la habitación:</span>
                                </FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Nombre:</Label>
                                            <Input value={bedroom.name} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Detalles:</Label>
                                            <Input type='textarea'
                                                   rows={1}
                                                   value={bedroom.detail} disabled/>
                                        </FormGroup>
                                    </Col>

                                    {typeof bedroom.categories !== 'undefined' && (
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Categorias</Label>
                                                <div>
                                                    {bedroom.categories.map((o, i) => (
                                                        <span className="mr-1">
                                                            <Badge color="light" className="mr-1">{o.category}</Badge>
                                                            {o.value}
                                                        </span>
                                                    ))}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    )}

                                    {typeof item.bedroom.type_services !== 'undefined' && (
                                        <Col sm={12} className="mb-3">
                                            <FormGroup>
                                                <Label>Tipos de servicios</Label>
                                                <div>
                                                    {item.bedroom.type_services.map((o, i) => (
                                                        <Badge color="light" className="mr-1">{o.name}</Badge>
                                                    ))}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <span className="bold">Información del cliente:</span>
                                </FormGroup>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>N° Documento:</Label>
                                            <Input value={client.document} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>Teléfono:</Label>
                                            <Input value={client.phone} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={7}>
                                        <FormGroup>
                                            <Label>Nombre:</Label>
                                            <Input value={client.name + ' ' + client.surname} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label>Placa:</Label>
                                            <Input value={item.plate} disabled/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            {companions.length > 0 && (
                                <Col md={12} className="mt-3">
                                    <div className="btn-block d-flex justify-content-between align-items-center">
                                        <span className="bold">Acompañantes</span>
                                    </div>
                                    <Table bordered size="sm" className="mt-2">
                                        <thead>
                                        <tr>
                                            <th width="1%">Dcumento</th>
                                            <th>Nombre</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {companions.map((o, i) => (
                                            <tr key={i}>
                                                <td>{o.document}</td>
                                                <td>{o.name}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            )}

                            {orders.length > 0 && (
                                <Col md={12} className="mt-3">
                                    <div className="btn-block d-flex justify-content-between align-items-center">
                                        <span className="bold">Productos adquiridos:</span>
                                    </div>
                                    <Table bordered size="sm" className="p-0 mt-2">
                                        <thead>
                                        <tr>
                                            <th>Usuario</th>
                                            <th>Total</th>
                                            <th>Pago</th>
                                            <th>Resto</th>
                                            <th width="1%">Fecha</th>
                                            <th width="1%"></th>
                                        </tr>
                                        </thead>
                                        {orders.map((o, i) => (
                                            <ItemCollapseReport item={o}/>
                                        ))}
                                    </Table>
                                </Col>
                            )}

                            {report_items.length > 0 && (
                                <Col md={12} className="mt-3">
                                    <div className="btn-block d-flex justify-content-between align-items-center">
                                        <span className="bold">
                                            {report_items.length > 0 ? 'Información del alojamiento:' : ''}
                                        </span>
                                    </div>
                                    <Table bordered size="sm" className="mt-2">
                                        <thead>
                                        <tr>
                                            <th>Usuario</th>
                                            <th width="1%">Horas</th>
                                            <th>Total</th>
                                            <th>Pago</th>
                                            <th>Resto</th>
                                            <th width="1%">Fecha</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {report_items.map((o, i) => (
                                            <tr key={i}>
                                                <td>{o.user_name}</td>
                                                <td className="nowrap">{o.hours > 0 ? o.hours : ''}</td>
                                                <td>{o.total > 0 ? User.stg.coin + ' ' + Util.num(o.total, 2) : ''}</td>
                                                <td className="text-primary">{o.cash > 0 ? User.stg.coin + ' ' + Util.num(o.cash, 2) : ''}</td>
                                                <td>{o.remaining > 0 ? User.stg.coin + ' ' + Util.num(o.remaining, 2) : ''}</td>
                                                <td className="nowrap">{o.date_created.datetime()}</td>
                                            </tr>
                                        ))
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            )}

                            <Col md={12} className="mt-3">
                                <div className="btn-block d-flex justify-content-between align-items-center">
                                    <span className="bold">
                                        {expenses.length > 0 ? 'Egresos / Ingresos:' : ''}
                                    </span>
                                </div>
                            </Col>

                            {expenses.length > 0 && (
                                <Col md={12} className="mt-3">
                                    <Table bordered size="sm">
                                        <thead>
                                        <tr>
                                            <th width="1%">Usuario</th>
                                            <th width="1%">Tipo</th>
                                            <th width="1%">Monto</th>
                                            <th>Razón</th>
                                            <th width="1%">Fecha</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {expenses.map((o, i) => (
                                            <tr>
                                                <td className="nowrap">{o.user_name}</td>
                                                <td>{o.type == 1 ? <span className="badge badge-success">Ingreso</span>
                                                    : <span className="badge badge-danger">Egreso</span>}</td>
                                                <td className="nowrap">{User.stg.coin} {Util.num(o.amount, 2)}</td>
                                                <td>{o.reason}</td>
                                                <td className="nowrap">{o.date_created.datetime()}</td>
                                            </tr>
                                        ))
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            )}
                        </Row>
                        <hr/>
                        <Row>
                            <Col md={6}></Col>
                            <Col md={6}>
                                <Card className="_card">
                                    <CardHeader>
                                        <span className="text-uppercase bold">Reporte</span>
                                    </CardHeader>
                                    <CardBody>
                                        <li className="d-flex justify-content-between align-items-center">
                                            Ingresos
                                            <span className="badge-pill">
                                                {User.stg.coin} {Util.num(item.money_income, 2)}
                                            </span>
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center">
                                            Egresos
                                            <span className="badge-pill">
                                                {User.stg.coin} {Util.num(item.money_expenditures, 2)}
                                            </span>
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center bold">
                                            Total
                                            <span
                                                className={"badge-pill bold " + (item.money_received < 0 ? "text-danger" : " text-success")}>
                                                {User.stg.coin} {Util.num(item.money_received, 2)}
                                            </span>
                                        </li>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Salir
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
