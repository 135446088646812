import axios from "axios";
import {User} from "./../inc/user";
import Util from "./utils";

export class Api {

    static DEBUG = process.env.NODE_ENV !== 'production';

    static URL_BASE = Api.DEBUG
         ? 'https://admin.eurotell.net/api'
         : 'https://admin.eurotell.net/api';

    static get(url, data, callback, loading) {

        if (typeof data === 'function') {
            loading = callback;
            callback = data;
            data = {};
        }

        if (typeof loading === 'undefined') {
            loading = true;
            Util.loading(true);
        } else if (loading) {
            Util.loading(loading);
        }

        Api.getAxios().get(url, {params: data})
            .then(function (response) {

                let data = response.data;

                if (loading) Util.loading(false);
                if (callback) callback(data);

            })
            .catch(function (error) {
                if (loading) Util.loading(false);
                if (callback) callback({
                    ok: false, msg: error.toString(), errors: []
                });
            });
    }

    static post(url, data, callback, loading) {

        if (typeof data === 'function') {
            loading = callback;
            callback = data;
            data = {};
        }

        if (typeof loading === 'undefined') {
            loading = true;
            Util.loading(true);
        } else if (loading) {
            Util.loading(loading);
        }

        Api.getAxios().post(url, data)
            .then(function (response) {

                let data = response.data;

                if (loading) Util.loading(false);
                if (callback) callback(data);

            })
            .catch(function (error) {
                if (loading) Util.loading(false);
                if (callback) callback({
                    ok: false, msg: error.toString(), errors: []
                });
            });
    }

    static getAxios() {
        return axios.create({
            baseURL: Api.URL_BASE,
            headers: {'Authorization': 'Bearer ' + User.token}
        });
    }

}