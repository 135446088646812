import React from 'react';
import {
    Button, Card, CardBody, CardHeader, FormGroup
} from 'reactstrap';
import Fils from "./widgets/fils";
import {User} from "../inc/user";
import AppSwitch from "@coreui/react/lib/Switch";
import Util from "../inc/utils";

export default class Fbs extends React.Component {
    state = {
        canEdit: User.can(this),
        items: [],
        interval: false,
        active: 0,
    };

    sendMessage = async (url) => {
        this.myWindow = await window.open(url, "myWindow", "width=200,height=300");
    };

    loadData = (rsp) => {
        this.setState({items: rsp.items})
    };

    interval = async () => {
        console.log(this.state.items.length);
        console.log(this.state.active);
        let active = this.state.active;
        this.sendMessage(this.state.items[active].message);
        active++;
        await this.setState({active: active});
        if (this.state.interval && this.state.active < this.state.items.length) {
            let random = Util.num(Math.random() * (20000 - 5000) + 5000, 0);
            console.log("random: ", random);
            setTimeout(() => {
                this.interval();
            }, random);
        }
    };

    render() {
        const state = this.state;
        return (
            <div>
                <Card className="mini _card">
                    <CardHeader>
                        <span className="text-uppercase bold">Mensajes facebook</span>
                        <div className="card-header-actions">
                            <AppSwitch
                                className="mdl"
                                color="primary"
                                variant="pill"
                                checked={state.interval === true}
                                onChange={(e) => {
                                    this.setState({
                                        interval: e.target.checked ? true : false
                                    });

                                    this.interval();
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">
                        <Fils {...this.props}
                              ref={instance => this.fils = instance}
                              endpoint={this.props.match.url}
                              state={this.loadData}
                              fields={[
                                  {
                                      'type': 'range',
                                      'title': 'Filtro por fecha',
                                      'nameFrom': 'date_from',
                                      'nameTo': 'date_to',
                                  },
                              ]}
                              cols={[
                                  {value: '#', width: '1%'},
                                  {alue: 'Usuario'},
                                  {alue: 'Fecha de creación', width: '1%'},
                                  {width: '1%'}
                              ]}
                              row={(o, i) => (
                                  <tr key={i}>
                                      <td>{o.id}</td>
                                      <td>{o.user}</td>
                                      <td className="nowrap">{o.date_created.datetime()}</td>
                                      <td>
                                          {this.state.canEdit && (
                                              <Button color="primary" size="sm"
                                                      onClick={() => this.sendMessage(o.message)}>
                                                  <i className="fa fa-paper-plane"/>
                                              </Button>
                                          )}
                                      </td>
                                  </tr>
                              )}
                        />
                    </CardBody>
                </Card>
            </div>)
    }
}