import React from 'react';
import {
    Button,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import clone from "clone-deep";

const STATE = {
    item: {
        id: 0,
        name: '',
        detail: '',
        price: '',
        sort: '0'
    },
    loading: false,
    error: null,
    errors: {},
    modal: false,
};

export default class CleaningRoomM extends _component {

    state = clone(STATE);

    add = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/reception/bedroom_info', {id: id}, (rsp) => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    item: rsp.item,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    cleaning = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/reception/cleaning', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Habitación limpiada correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Limpiando...');
        });
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}>
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        {item.id > 0 ? 'Limpiar' : 'Limpiar'} habitación
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <label>Nombre</label>
                                    <Input value={item.name} disabled/>
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <label>Detalles</label>
                                    <Input type='textarea' value={item.detail} rows={3} disabled/>
                                </FormGroup>
                            </Col>

                            {typeof item.categories !== 'undefined' && item.categories.map((o) => (
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>{o.category}</Label>
                                        <Input value={o.value} disabled/>
                                    </FormGroup>
                                </Col>))
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.cleaning}>
                            <i className="fa fa-check"/> Limpiar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
