import React from 'react';
import {
    Button, Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, ModalBody
} from 'reactstrap';
import Fils from "./widgets/fils";
import ReportAccommodationsM from "./ReportAccommodationsM";
import ExpensesM from "./ExpensesM";
import {User} from "../inc/user";
import Util from "../inc/utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class ReportAccommodations extends React.Component {
    state = {
        canEdit: User.can(this),
        canEditExpenses: User.can("/expenses"),
        results: '0',
        this_total_income: '0',
        this_total_expenses: '0',
        this_total_received: '0',
        view_total: false,
        total_income: '0',
        total_expenses: '0',
        total_received: '0',
    };

    loadData = (rsp) => {
        this.setState({
            ...this.state,
            results: !rsp.view_total ? 0 : this.state.results,
            view_total: rsp.view_total,
            this_total_income: rsp.this_total_income,
            this_total_expenses: rsp.this_total_expenses,
            this_total_received: rsp.this_total_received,
            total_income: rsp.total_income,
            total_expenses: rsp.total_expenses,
            total_received: rsp.total_received,
        });
    };

    changed = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value
        });
    };

    render() {
        return (
            <div>
                <Card className="_card">
                    <CardHeader>
                        <span className="text-uppercase bold">Reporte Alojamientos</span>
                        <div className="card-header-actions">
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">
                        <Fils {...this.props}
                              ref={ins => this.fils = ins}
                              endpoint={this.props.match.url}
                              state={this.loadData}
                              fields={[
                                  {
                                      'type': 'range',
                                      'title': 'Filtro por fecha',
                                      'nameFrom': 'date_from',
                                      'nameTo': 'date_to',
                                  },
                                  {
                                      'type': 'select',
                                      'title': 'Registros',
                                      'name': 'occupied',
                                      'items': [
                                          {'id': '1', 'name': 'Incluir ocupadas'}
                                      ]
                                  },
                                  {
                                      'type': 'select2',
                                      'title': 'Cliente',
                                      'name': 'client',
                                      'endpoint': 'clients'
                                  },
                              ]}
                              cols={[
                                  {value: '#', width: '1%'},
                                  {value: 'Habitación'},
                                  {value: 'Cliente'},
                                  {value: 'Documento'},
                                  {value: 'Teléfono'},
                                  {value: 'Ingresos'},
                                  {value: 'Egresos'},
                                  {value: 'Total'},
                                  {value: 'Fecha de creación', width: '1%'},
                                  {width: '1%'}
                              ]}
                              row={(o, i) => (
                                  <tr key={i}>
                                      <td>{o.id}</td>
                                      <td>{o.be_name}</td>
                                      <td>{o.cli_name}</td>
                                      <td>{o.cli_document}</td>
                                      <td>{o.cli_phone}</td>
                                      <td className="nowrap">{User.stg.coin} {Util.num(o.moneyIncome, 2)}</td>
                                      <td className="nowrap">{User.stg.coin} {Util.num(o.moneyExpenditures, 2)}</td>
                                      <td className={"nowrap " + (o.moneyReceived < 0 ? "text-danger" : " text-success")}>
                                          {User.stg.coin} {Util.num(o.moneyReceived, 2)}
                                      </td>
                                      <td className="nowrap">{o.date_created.datetime()}</td>
                                      <td>
                                          {this.state.canEdit && (
                                              <div className="btn-group">
                                                  {this.state.canEditExpenses && (
                                                      <Button color="info" size="sm"
                                                              onClick={() => this.expense.add(o.id)}>
                                                          <i className="fa fa-money"/>
                                                      </Button>
                                                  )}

                                                  <Button color="primary" size="sm"
                                                          onClick={() => this.modal.add(o.id)}>
                                                      <i className="fa fa-eye"/>
                                                  </Button>
                                              </div>
                                          )}
                                      </td>
                                  </tr>
                              )}
                        />
                        <hr/>
                        <Row>
                            <Col md={8}></Col>
                            <Col md={4}>
                                <Card className="_card">
                                    <CardHeader>
                                        <span className="text-uppercase bold">Reportes</span>
                                        {this.state.view_total && (
                                            <div className="card-header-actions">
                                                <Input name="results"
                                                       value={this.state.results}
                                                       onChange={this.changed}
                                                       type="select">
                                                    <option value="0">Esta ventana</option>
                                                    <option value="1">Todo</option>
                                                </Input>
                                            </div>
                                        )}
                                    </CardHeader>
                                    <CardBody>
                                        <li className="d-flex justify-content-between align-items-center">
                                            Ingresos
                                            {this.state.results == 0
                                                ?
                                                <span className="badge-pill">
                                                    {User.stg.coin} {Util.num(this.state.this_total_income, 2)}
                                                </span>
                                                :
                                                <span className="badge-pill">
                                                    {User.stg.coin} {Util.num(this.state.total_income, 2)}
                                                </span>
                                            }
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center">
                                            Egresos
                                            {this.state.results == 0
                                                ?
                                                <span className="badge-pill">
                                                    {User.stg.coin} {Util.num(this.state.this_total_expenses, 2)}
                                                </span>
                                                :
                                                <span className="badge-pill">
                                                    {User.stg.coin} {Util.num(this.state.total_expenses, 2)}
                                                </span>
                                            }
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center bold">
                                            Total
                                            {this.state.results == 0
                                                ?
                                                <span
                                                    className={"badge-pill bold " + (this.state.this_total_received < 0 ? "text-danger" : " text-success")}>
                                                {User.stg.coin} {Util.num(this.state.this_total_received, 2)}
                                            </span>
                                                :
                                                <span
                                                    className={"badge-pill bold " + (this.state.total_received < 0 ? "text-danger" : " text-success")}>
                                                {User.stg.coin} {Util.num(this.state.total_received, 2)}
                                            </span>
                                            }
                                        </li>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <ReportAccommodationsM ref={ins => this.modal = ins}
                                       callback={() => this.fils.loadData()}/>

                <ExpensesM ref={ins => this.expense = ins}
                           callback={() => this.fils.loadData()}/>
            </div>
        )
    }
}