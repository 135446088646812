import React from 'react';
import {
    Badge,
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Progress,
    Row,
    Table,
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import clone from "clone-deep";
import TosellM from "./TosellM";
import {User} from "../inc/user";
import Util from "../inc/utils";
import AddTimeM from "./AddTimeM";
import ItemCollapse from "./widgets/item_collapse";

const STATE = {
    item: {
        date_entry: '',
        date_departure: '',
        progress_total: '',
        progress_consumed: '',
        progress: '',
        plate: '',
        price: '',
        hours: '',
        timeEntry: '',
        departureTime: '',
        user: {
            name: '',
        },
        client: {
            id: 0,
            name: '',
        },
        companions: {},
        bedroom: {
            id: 0,
            name: '',
            detail: '',
            price: '',
            active: 0,
        },
        orders: [],
        report_items: [],
        rate: {
            total: '0',
            cash: '0',
            remaining: '0',
            charge: '0',
        },
    },
    loading: false,
    error: null,
    errors: {},
    modal: false,
    collapse: false,
}

export default class ActiveRoomM extends _component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    state = clone(STATE);

    changedRate = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        let charge = this.state.item.rate.charge;
        let cash = this.state.item.rate.cash;
        let remaining = this.state.item.rate.remaining;

        if (name === "cash") {
            cash = value != '' ? value > 0 ? Util.num(value) : 0 : '';
            remaining = Util.num(Util.num(cash) - Util.num(charge), 2);
            this.setState({
                item: {
                    ...this.state.item,
                    rate: {
                        ...this.state.item.rate,
                        cash: cash,
                        remaining: remaining
                    }
                }
            });
        } else {
            this.setState({
                item: {
                    ...this.state.item,
                    rate: {
                        ...this.state.item.rate,
                        [name]: value
                    }
                }
            });
        }
    };

    add = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/reception/active', {id: id}, (rsp) => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    item: rsp.item,
                });

                console.log(rsp.item);
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    vacate(id) {
        const rate = this.state.item.rate;
        let remaining = (rate.remaining * -1);

        if (remaining <= 0) {
            remaining = 0;
            Api.get('/reception/vacate', {
                id: id,
                total: rate.total,
                cash: rate.total,
                remaining: remaining,
                charge: rate.charge
            }, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Habitación desocupada correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Desocupando...');
        } else {
            let amount = '<span class="text-danger bold">' + User.stg.coin + ' ' + Util.num(remaining, 2) + '</span>';
            let message = 'Falta cobrar ' + amount + '<br>Es necesario cobrar para desocupar la habitación.';
            Alert.message(message);
        }
    }

    toggle() {
        this.setState(state => ({collapse: !state.collapse}));
    }

    render() {
        const {item, errors} = this.state;
        const colorBlue = '#20a8d8';
        const classBetween = 'btn-block d-flex justify-content-between align-items-center';
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggleModal}>Detalle habitación</ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="bold">Hora de ingreso:</Label>
                                    <Input value={item.date_entry.datetime()} disabled
                                           className="bg-white pdg-0 border-0"/>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="bold">Hora de salida:</Label>
                                    <Input value={item.date_departure.datetime()} disabled
                                           className="bg-white pdg-0 border-0"/>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <Progress color={item.progress}
                                          value={item.progress_consumed}
                                          max={item.progress_total}/>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md={6}>
                                <FormGroup>
                                    <span className="bold">Información de la habitación:</span>
                                </FormGroup>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Nombre:</Label>
                                            <Input value={item.bedroom.name} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Detalles:</Label>
                                            <Input type='textarea'
                                                   rows={1}
                                                   value={item.bedroom.detail}
                                                   disabled/>
                                        </FormGroup>
                                    </Col>

                                    {typeof item.bedroom.categories !== 'undefined' && (
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Categorias</Label>
                                                <div>
                                                    {item.bedroom.categories.map((o, i) => (
                                                        <span className="mr-1">
                                                            <Badge color="light" className="mr-1">{o.category}</Badge>
                                                            {o.value}
                                                        </span>
                                                    ))}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <span className="bold">Información del cliente:</span>
                                </FormGroup>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label>N° Documento:</Label>
                                            <Input value={item.client.document} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label>Teléfono:</Label>
                                            <Input value={item.client.phone} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label>Nombre:</Label>
                                            <Input value={item.client.name} disabled/>
                                        </FormGroup>
                                    </Col>
                                    {typeof item.bedroom.type_services !== 'undefined' && (
                                        <Col sm={12} className="mb-3">
                                            <FormGroup>
                                                <Label>Tipos de servicios</Label>
                                                <div>
                                                    {item.bedroom.type_services.map((o, i) => (
                                                        <Badge color="light" className="mr-1">{o.name}</Badge>
                                                    ))}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Col>

                            {item.companions.length > 0 && (
                                <Col md={12} className="mt-3">
                                    <div className="btn-block d-flex justify-content-between align-items-center">
                                        <span className="bold">Acompañantes</span>
                                    </div>
                                    <Table bordered size="sm" className="mt-2">
                                        <thead>
                                        <tr>
                                            <th width="1%">Dcumento</th>
                                            <th>Nombre</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {item.companions.map((o, i) => (
                                            <tr>
                                                <td>{o.document}</td>
                                                <td>{o.name}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            )}

                            <Col md={12}>
                                <div className="btn-block d-flex justify-content-between align-items-center">
                                    <span className="bold">
                                        {item.orders.length > 0 ? 'Productos adquiridos:' : ''}
                                    </span>
                                    <button className="btn btn-outline-dark"
                                            onClick={() => this.tosell.add(item.bedroom.active)}>
                                        <span className="fa fa-shopping-basket mr-2"/>
                                        Generar venta
                                    </button>
                                </div>
                            </Col>

                            <Col md={12} className="mt-3">
                                {item.orders.length > 0 && (
                                    <div className="card-body p-0">
                                        <Table bordered size="sm" responsive className="p-0 m-0">
                                            <thead>
                                            <tr>
                                                <th>Usuario</th>
                                                <th>Total</th>
                                                <th>Pago</th>
                                                <th>Falta pagar</th>
                                                <th width="1%">Fecha</th>
                                                <th width="1%"></th>
                                            </tr>
                                            </thead>
                                            {item.orders.map((o, i) => (
                                                <ItemCollapse item={o}/>
                                            ))}
                                        </Table>
                                    </div>
                                )}
                            </Col>

                            <Col md={12} className="mt-3">
                                <div className="btn-block d-flex justify-content-between align-items-center">
                                    <span className="bold">
                                        {item.report_items.length > 0 ? 'Información del alojamiento:' : ''}
                                    </span>
                                    <button className="btn btn-outline-dark"
                                            onClick={() => this.addtime.add(item.bedroom.id)}>
                                        <span className="fa fa-clock-o mr-2"/>
                                        Agregar tiempo
                                    </button>
                                </div>
                            </Col>

                            {item.report_items.length > 0 && (
                                <Col md={12} className="mt-3">
                                    <Table bordered size="sm" responsive>
                                        <thead>
                                        <tr>
                                            <th>Usuario</th>
                                            <th>Horas</th>
                                            <th>Precio</th>
                                            <th>Pago</th>
                                            <th>Falta pagar</th>
                                            <th width="1%">Fecha</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            item.report_items.map((o, i) => (
                                                <tr>
                                                    <td>{o.user_name}</td>
                                                    <td>{o.hours > 0 ? o.hours : ''}</td>
                                                    <td>{o.total > 0 ? User.stg.coin + ' ' + Util.num(o.total, 2) : ''}</td>
                                                    <td className="text-primary">{o.cash > 0 ? User.stg.coin + ' ' + Util.num(o.cash, 2) : ''}</td>
                                                    <td className="text-danger">
                                                        {o.remaining > 0 ? User.stg.coin + ' ' + Util.num(o.remaining, 2) : ''}
                                                    </td>
                                                    <td className="nowrap">{o.date_created.datetime()}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            )}

                            {item.rate.total > 0 && (
                                <Col md={6}></Col>
                            )}

                            {item.rate.total > 0 && (
                                <Col md={6}>
                                    <div className="card">
                                        <div className="card-header inputSimpleText">
                                            <b>Facturación</b>
                                        </div>
                                        <div className="card-body p-2">
                                            <div className={classBetween}>
                                                <span style={{'fontWeight': 'bold',}}>Total</span>
                                                <span style={{
                                                    'fontWeight': 'bold',
                                                    'color': colorBlue,
                                                    'cursor': 'pointer'
                                                }} onClick={() => {
                                                    this.setState({
                                                        item: {
                                                            ...this.state.item,
                                                            rate: {
                                                                ...this.state.item.rate,
                                                                cash: Util.num(this.state.item.rate.total, 2),
                                                                remaining: 0
                                                            }
                                                        }
                                                    });
                                                }}>
                                                {User.stg.coin + ' ' + Util.num(item.rate.total, 2)}
                                            </span>
                                            </div>
                                            <hr/>
                                            <div className={classBetween}>
                                                <FormGroup>
                                                    <Label>Efectivo</Label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                                <span
                                                                    className="input-group-text">{User.stg.coin}</span>
                                                        </div>
                                                        <Input type="number"
                                                               name="cash"
                                                               value={item.rate.cash}
                                                               onChange={this.changedRate}/>
                                                    </div>
                                                </FormGroup>
                                                <div className="m-2"/>
                                                <FormGroup>
                                                    <Label
                                                        className={item.rate.remaining < 0 ? 'text-danger' : 'text-success'}>
                                                        {item.rate.remaining < 0 ? 'Falta cobrar: ' : 'Vuelto: '}
                                                    </Label>
                                                    <Input
                                                        className={item.rate.remaining < 0 ? 'text-danger' : 'text-success'}
                                                        value={User.stg.coin + ' ' + Util.num(item.rate.remaining < 0 ? (item.rate.remaining * -1) : item.rate.remaining, 2)}/>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}

                            {item.plate != '' && (
                                <Col md={6}></Col>
                            )}

                            {item.plate != '' && (
                                <Col md={6} className="">
                                    <div className="alert alert-info right">
                                        Tiene vehículo placa: <b>{item.plate}</b>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Salir
                        </Button>
                        <Button color="danger" onClick={() => this.vacate(item.bedroom.id)}>
                            <i className="fa fa-check"/> Desocupar
                        </Button>
                    </ModalFooter>
                </fieldset>

                <TosellM ref={ins => this.tosell = ins}
                         callback={() => this.add(item.bedroom.id)}/>

                <AddTimeM ref={ins => this.addtime = ins}
                          callback={() => this.add(item.bedroom.id)}/>
            </Modal>
        );
    }
}
