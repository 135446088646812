import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, FormGroup, Input, Row} from "reactstrap";
import {Api} from "../../inc/api";

export default class RangePicker extends Component {
    state = {
        focus: false,
        items: [],
        inputValue: '',
        placeholder: 'dd/mm/aaaa',
        name: '',
        from: '',
        to: '',
    };

    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.loadData();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.focus) {
                this.callback();
            }
        }
    }

    loadData = async () => {
        let items = this.state.items;

        if (items.length == 0) {
            items = await this.getOptions();
        }

        this.setState({
            items: items,
        });

        if (typeof this.props.selected === 'number') {
            this.select(this.state.items[this.props.selected]);
        }
    };

    clickInput = async () => {
        let focus = true;
        if (this.state.focus) {
            focus = false;
            document.getElementById('input').blur();
        } else {
            let items = this.state.items;

            if (items.length == 0) {
                items = await this.getOptions();
            }

            this.setState({
                items: items,
                focus: true,
            });
        }
        this.setState({focus});
    };

    getOptions = () => {
        return new Promise(resolve => {
            Api.get('/report_accommodations/options_filter', rsp => {
                if (rsp.ok) {
                    resolve(rsp.items);
                } else {
                    resolve([]);
                }
            }, false);
        });
    };

    select = async (item) => {
        let name = item.name + ': ' + item.from.date() + ' - ' + item.to.date();
        let inputValue = this.props.name ? item.name : name;
        await this.setState({
            inputValue: inputValue,
            name: item.name,
            from: item.from,
            to: item.to,
        });
        this.callback();
    };

    changed = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let inputValue = 'Personalizado: ';

        if (name === 'from') {
            inputValue += value.date() + ' - ' + this.state.to.date();
        } else if (name === 'to') {
            inputValue += this.state.from.date() + ' - ' + value.date();
        }

        inputValue = this.props.name ? 'Personalizado' : inputValue;

        this.setState({
            ...this.state,
            [name]: value,
            inputValue,
            name: 'Personalizado'
        });
    };

    apply = () => {
        this.callback();
    };

    clear = async () => {
        if (typeof this.props.selected === 'number') {
            this.select(this.state.items[this.props.selected]);
        } else {
            await this.setState({
                inputValue: '',
                name: '',
                from: '',
                to: '',
            });
            this.callback();
        }
    };

    callback = () => {
        let rsp = new Object();
        rsp.inputValue = this.state.inputValue;
        rsp.name = this.state.name;
        rsp.from = this.state.from;
        rsp.to = this.state.to;
        this.props.callback(rsp);
        this.setState({focus: false});
    };

    render() {
        var styleContainer = new Object();
        styleContainer.background = 'white';
        styleContainer.width = '100%';
        styleContainer.zIndex = '9';
        styleContainer.position = 'absolute';
        styleContainer.overflow = 'hidden';
        styleContainer.borderRadius = '5px';

        return <div ref={this.setWrapperRef}>
            <div className="input-group m-0">
                <Input
                    id='input'
                    readonly="readonly"
                    className="pointer bg-white "
                    name="inputValue"
                    value={this.state.inputValue}
                    placeholder={this.state.placeholder}
                    onClick={this.clickInput}
                />
                <div className="input-group-append" onClick={this.clickInput}>
                    <span className="input-group-text bg-white pointer">
                        <i className="fa fa-calendar"></i>
                    </span>
                </div>
            </div>
            {this.state.focus && (
                <div className=""
                     style={{
                         'position': 'relative',
                         'background': '',
                         'height': '0px'
                     }}>
                    <div className="border mt-2"
                         style={styleContainer}>
                        <li className="d-flex list-group-item-action justify-content-between align-items-center p-2 bold"
                            style={{'background': '#F0F3F5'}}>
                            <span>Seleccionar filtro fecha</span>
                            <span onClick={this.clear} className="pointer"><i className="fa fa-times"/></span>
                        </li>
                        <hr className="m-0"/>
                        {this.state.items.map((o, i) => (
                            <li key={i}
                                className="d-flex list-group-item-action p-2 pointer"
                                onClick={() => this.select(o)}>
                                <span>{o.name}</span>
                            </li>
                        ))}

                        <Row className="p-2 pb-0">
                            <Col md={this.props.fils ? 12 : 6}>
                                <FormGroup>
                                    <Input name="from"
                                           value={this.state.from}
                                           type="date"
                                           style={{'width': '100%'}}
                                           onChange={this.changed}/>
                                </FormGroup>
                            </Col>
                            <Col md={this.props.fils ? 12 : 6}>
                                <FormGroup>
                                    <Input name="to"
                                           value={this.state.to}
                                           type="date"
                                           style={{'width': '100%'}}
                                           className={this.props.fils ? "mt-2 mb-2" : ""}
                                           onChange={this.changed}/>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <Button className={"btn-block"}
                                        color="success"
                                        onClick={this.apply}>
                                    Aplicar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </div>
    }
}

RangePicker.propTypes = {
    children: PropTypes.element.isRequired,
    fils: PropTypes.bool,
    name: PropTypes.bool,
    selected: PropTypes.number,
    callback: PropTypes.func,
};