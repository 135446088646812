import React from 'react';
import {
    Button, Card, CardBody, CardHeader, Row, Col, Table, FormGroup, Input
} from 'reactstrap';
import Fils from "./widgets/fils";
import BoxsM from "./BoxsM";
import {User} from "../inc/user";
import {Api} from "../inc/api";
import Alert from "../inc/alert";
import {toast} from "react-toastify";
import Util from "../inc/utils";

const ITEM = {
    id: 0,
    opening: 0,
    moneyIncome: 0,
    moneyExpenditures: 0,
    moneyReceived: 0,
    items: [],
};

export default class Boxs extends React.Component {
    state = {
        canEdit: User.can(this),
        item: ITEM
    };

    getActive() {
        Api.post('/boxs/active', (rsp) => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    item: rsp.item
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        }, false);
    }

    componentWillMount() {
        this.getActive();
    }

    closeBox = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/boxs/close_box', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Caja cerrada correctamente');
                    this.setState({item: ITEM});
                    this.getActive();
                    this.fils.loadData();
                } else {
                    Alert.error(rsp.msg);
                }
            });
        });
    };

    render() {
        const {item} = this.state;
        return (
            <div>
                <Card className="_card">
                    <CardBody className="pdg-5">
                        <Row>
                            <Col md={6}>
                                <Card className="_card">
                                    <CardHeader>
                                        <span className="text-uppercase bold">Historial Cajas</span>
                                        <div className="card-header-actions">
                                            {item.id == 0 && (
                                                <Button className="bold text-uppercase" color="primary"
                                                        onClick={() => this.modal.add()}>Abrir Caja
                                                </Button>
                                            )}
                                        </div>
                                    </CardHeader>
                                    <CardBody className="pdg-5">
                                        <Fils {...this.props}
                                              ref={ins => this.fils = ins}
                                              endpoint={this.props.match.url}
                                              notFilterWord={true}
                                              collapse={true}
                                              striped={false}
                                              fields={[
                                                  {
                                                      'type': 'range',
                                                      'title': 'Filtro por fecha',
                                                      'nameFrom': 'date_from',
                                                      'nameTo': 'date_to',
                                                  },
                                                  {
                                                      'type': 'select2',
                                                      'title': 'Usuario',
                                                      'name': 'user',
                                                      'endpoint': 'users'
                                                  },
                                                  {
                                                      'type': 'select',
                                                      'title': 'Estado',
                                                      'name': 'state',
                                                      'items': [
                                                          {'id': '1', 'name': 'Activas'},
                                                          {'id': '2', 'name': 'Cerradas'}
                                                      ]
                                                  },
                                              ]}
                                              cols={[
                                                  {value: '', width: '1%'},
                                                  {value: 'Usuario'},
                                                  {value: 'Apertura', width: '1%'},
                                                  {value: 'Estado', width: '1%'},
                                                  {value: 'Fecha de creación', width: '1%'},
                                                  {width: '1%'}
                                              ]}

                                              row={(o, i, collapse, toggle) => (
                                                  <tr style={{'background': (i % 2) || collapse ? '#F0F3F5' : ''}}>
                                                      <td>
                                                          {o.items.length > 0 && (
                                                              <Button outline color="info" size="sm" className="mr-0"
                                                                      onClick={toggle}>
                                                                  <i className={collapse ? 'fa fa-minus' : 'fa fa-plus'}/>
                                                              </Button>
                                                          )}
                                                      </td>
                                                      <td>{o.us_name}</td>
                                                      <td>{User.stg.coin + ' ' + Util.num(o.opening, 2)}</td>
                                                      <td>{o.state == 1
                                                          ? <span className="badge badge-success">Activa</span>
                                                          : <span className="badge badge-danger">Cerrada</span>}
                                                      </td>
                                                      <td className="nowrap">{o.date_created.datetime()}</td>
                                                      <td className="mr-0">
                                                          {this.state.canEdit && (
                                                              <Button color="primary" size="sm" className="mr-0"
                                                                      onClick={() => this.modal.edit(o.id)}>
                                                                  <i className="fa fa-pencil"/>
                                                              </Button>
                                                          )}
                                                      </td>
                                                  </tr>
                                              )}

                                              cols2={(o, i) => (
                                                  <tr>
                                                      <td className="border-bottom-0 border-right-0 border-top-0">#</td>
                                                      <td className="border-0">Razón</td>
                                                      <td className="border-0 nowrap">Monto</td>
                                                      <td className="border-0 nowrap"></td>
                                                      <td className="border-0 nowrap">Fecha de creación</td>
                                                      <td className="border-bottom-0 border-left-0 border-top-0 mr-0"></td>
                                                  </tr>
                                              )}

                                              row2={(o, i) => (
                                                  <tr>
                                                      <td className="border-bottom-0 border-right-0 border-top-0">{o.id_report}</td>
                                                      <td className="border-0">{o.reason}</td>
                                                      <td className="border-0 nowrap">{User.stg.coin + ' ' + Util.num(o.amount, 2)}</td>
                                                      <td className="border-0 nowrap"></td>
                                                      <td className="border-0 nowrap">{o.date_created.datetime()}</td>
                                                      <td className="border-bottom-0 border-left-0 border-top-0 mr-0"></td>
                                                  </tr>
                                              )}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {item.id > 0 && (<Col md={6}>
                                <Card className="_card">
                                    <CardHeader>
                                        <span className="text-uppercase bold">Caja Activa</span>
                                        <div className="card-header-actions">
                                            <Button className="bold text-uppercase"
                                                    color="danger"
                                                    onClick={this.closeBox}>Cerrar caja
                                            </Button>
                                        </div>
                                    </CardHeader>
                                    <CardBody className="pdg-5">
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <label>Apertura</label>
                                                    <Input readOnly
                                                           value={User.stg.coin + ' ' + Util.num(item.opening, 2)}
                                                           className="bg-white"/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <label>Ingresos</label>
                                                    <Input readOnly
                                                           value={User.stg.coin + ' ' + Util.num(item.moneyIncome, 2)}
                                                           className="bg-white"/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <label>Egresos</label>
                                                    <Input readOnly
                                                           value={User.stg.coin + ' ' + Util.num(item.moneyExpenditures, 2)}
                                                           className="bg-white"/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <label>Total en caja</label>
                                                    <Input readOnly
                                                           value={User.stg.coin + ' ' + Util.num(item.moneyReceived, 2)}
                                                           className="bg-white"/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {item.items.length > 0 && (
                                            <Table borderless responsive style={{'borderTop': '2px solid #F0F3F5'}}>
                                                <thead>
                                                <tr>
                                                    <th width="1%">#</th>
                                                    <th>Razón</th>
                                                    <th width="1%">Monto</th>
                                                    <th width="1%">Fecha de creación</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {item.items.map((o, i) => (
                                                    <tr style={{'background': (i % 2) ? '' : '#F0F3F5'}}>
                                                        <td className="nowrap">{o.id_report}</td>
                                                        <td>{o.reason}</td>
                                                        <td className="nowrap">{User.stg.coin + ' ' + Util.num(o.amount, 2)}</td>
                                                        <td className="nowrap">{o.date_created.datetime()}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>)}
                        </Row>
                    </CardBody>
                </Card>

                <BoxsM ref={instance => this.modal = instance}
                       callback={() => {
                           this.setState({item: ITEM});
                           this.getActive();
                           this.fils.loadData();
                       }}/>
            </div>)
    }
}