import React from 'react';
import * as queryString from "query-string";

export default class _component extends React.Component {

    state = {
        item: {},
        loading: false,
    };

    showLoading = () => {
        this.setState({loading: true});
    };

    hideLoading = () => {
        this.setState({loading: false});
    };

    params = {};

    constructor(props) {
        super(props);

        if (props.match && props.location) {
            this.params = {...props.match.params, ...queryString.parse(props.location.search)};
        }

    }

    changed = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            item: {
                ...this.state.item,
                [name]: value
            }
        });
    };

    changedState = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value
        });
    };

    // toggle modal en caso de estar en un componente de modal
    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

}