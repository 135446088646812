import React from 'react';
import {
    Button, Card, CardBody, CardHeader, Row, Col, Progress
} from 'reactstrap';
import Fils from "./widgets/fils";
import TypeServicesM from "./TypeServicesM";
import {User} from "../inc/user";
import Filters from "./widgets/filters";
import Util from "../inc/utils";

export default class ReportDashboard extends React.Component {

    state = {
        canEdit: User.can(this),
        item: {
            summary: {
                total: 0,
                expenditures: 0,
                profit: 0,
            },
            acommodations: {
                icome: 0,
                expenditures: 0,
                total: 0,
            },
            orders: {
                icome: 0,
                cost: 0,
                profit: 0,
            },
            expenses: {
                icome: 0,
                expenditures: 0,
                total: 0,
            }
        }
    };

    loadData = (rsp) => {
        this.setState({
            ...this.state,
            item: rsp.item,
        });
    };

    render() {
        const {item} = this.state;
        const {summary, expenses, acommodations, orders} = this.state.item;

        console.log('expenses: ', expenses);

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <h2 className="text-uppercase bold">Dashboard</h2>
                    </Col>
                    <Col md={12}>
                        <div className="bg-white p-2 mt-3 rounded">
                            <Filters {...this.props}
                                     ref={ins => this.fils = ins}
                                     endpoint={this.props.match.url}
                                     notFilterWord={true}
                                     rangeSelected={0}
                                     state={this.loadData}
                                     fields={[
                                         {
                                             'type': 'range',
                                             'title': 'Filtro por fecha',
                                             'nameFrom': 'date_from',
                                             'nameTo': 'date_to',
                                         },
                                         {
                                             'type': 'select2',
                                             'title': 'Usuario',
                                             'name': 'user',
                                             'endpoint': 'users'
                                         },
                                         {
                                             'type': 'select2',
                                             'title': 'Cliente',
                                             'name': 'client',
                                             'endpoint': 'clients'
                                         },
                                     ]}>
                            </Filters>
                        </div>
                    </Col>

                    <Col md={3} className="mt-3">
                        <div className="card border-0" style={{background: '#1E1E2D'}}>
                            <div className="card-body">
                                <div className="btn-block text-muted d-flex justify-content-between align-items-center">
                                    <span className="c-purple h3">
                                        {User.stg.coin + ' ' + Util.num(summary.total, 2)}
                                    </span>
                                    <i><img src='img/icon_money.png' width="28"/></i>
                                </div>
                                <div className="lead text-secondary">Total ingresos</div>

                                <Progress color='warning'
                                          className="mt-4"
                                          value={summary.expenditures}
                                          max={summary.total > 0 ? summary.total : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Costo productos</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(summary.expenditures, 2)}</span>
                                </div>

                                <Progress color='purple'
                                          className="mt-4"
                                          value={summary.profit}
                                          max={summary.total > 0 ? summary.total : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Ganacias</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(summary.profit, 2)}</span>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={3} className="mt-3">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="btn-block text-muted d-flex justify-content-between align-items-center">
                                    <span className="text-info h3">
                                        {User.stg.coin + ' ' + Util.num(acommodations.total, 2)}
                                    </span>
                                    <i><img src='img/icon_bed.png' width="28"/></i>
                                </div>
                                <div className="lead text-secondary">Alojamiento</div>

                                <Progress color='danger'
                                          className="mt-4"
                                          value={acommodations.expenditures}
                                          max={acommodations.total > 0 ? acommodations.total : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Egresos</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(acommodations.expenditures, 2)}</span>
                                </div>

                                <Progress color='success'
                                          className="mt-4"
                                          value={acommodations.icome}
                                          max={acommodations.total > 0 ? acommodations.total : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Ganancia</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(acommodations.icome, 2)}</span>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={3} className="mt-3">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="btn-block text-muted d-flex justify-content-between align-items-center">
                                    <span className="text-success h3">
                                        {User.stg.coin + ' ' + Util.num(orders.icome, 2)}
                                    </span>
                                    <i><img src='img/icon_product.png' width="28"/></i>
                                </div>
                                <div className="lead text-secondary">Productos</div>

                                <Progress color='warning'
                                          className="mt-4"
                                          value={orders.cost}
                                          max={orders.icome > 0 ? orders.icome : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Costo</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(orders.cost, 2)}</span>
                                </div>

                                <Progress color='success'
                                          className="mt-4"
                                          value={orders.profit}
                                          max={orders.icome > 0 ? orders.icome : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Ganancia</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(orders.profit, 2)}</span>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={3} className="mt-3">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="btn-block text-muted d-flex justify-content-between align-items-center">
                                    <span className="text-dark h3">
                                        {User.stg.coin + ' ' + Util.num(expenses.total, 2)}
                                    </span>
                                    <i><img src='img/icon_expense.png' width="28"/></i>
                                </div>
                                <div className="lead text-secondary">Egresos / Ingresos</div>

                                <Progress color='danger'
                                          className="mt-4"
                                          value={expenses.expenditures}
                                          max={expenses.total > 0 ? expenses.total : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Egresos</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(expenses.expenditures, 2)}</span>
                                </div>

                                <Progress color='success'
                                          className="mt-4"
                                          value={expenses.icome}
                                          max={expenses.total > 0 ? expenses.total : 100}/>
                                <div className="d-flex justify-content-between mt-2">
                                    <span className="text-secondary">Ingresos</span>
                                    <span
                                        className="text-secondary">{User.stg.coin + ' ' + Util.num(expenses.icome, 2)}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <TypeServicesM ref={instance => this.modal = instance}
                               callback={() => this.fils.loadData()}/>

            </div>
        )
    }
}