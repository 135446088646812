import React from 'react';
import {
    Button,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    UncontrolledDropdown
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import {User} from "../inc/user";
import Util from "../inc/utils";
import AsyncSelect from "react-select/lib/Async";
import AppSwitch from "@coreui/react/es/Switch";

const STATE = {
    item: {
        id: 0,
        name: '',
        detail: '',
        stock: '',
        price: '0',
        edit_price: '0',
        increment: '0',
        discount: '0',
        charge: '0',
        quantity: '1'
    },
    index: -1,
    products: [],
    loading: false,
    error: null,
    errors: {},
    modal: false,
}

export default class ShopM extends _component {

    state = clone(STATE);

    add = (item, products) => {
        let index = -1;

        products.map((o, i) => {
            if (o.id == item.id) {
                index = i;
                item = o;
            }
        });

        this.setState({
            ...clone(STATE),
            item: item,
            products: products,
            index: index,
            modal: true,
        });
    };


    addItem = () => {
        let error = '';
        let modal = true;
        let quantity = Util.num(this.state.item.quantity, 0);
        let price = this.state.item.price;
        let discount = this.state.item.discount;

        if (quantity > 0 && (this.state.item.stock - quantity) >= 0) {
            if ((discount - (quantity * price)) <= 0) {
                if (this.state.index >= 0) {
                    this.state.products.splice(this.state.index, 1, this.state.item);
                } else {
                    this.state.products.push(this.state.item);
                }
                modal = false;
                if (typeof this.props.callback === 'function') this.props.callback(this.state.products);
            } else {
                error = 'Descuento no válido.';
            }
        } else {
            error = 'Cantidad no disponible.';
        }

        this.setState({
            ...this.state,
            error: error,
            modal: modal,
        });

    }

    removeItem = () => {
        this.state.products.splice(this.state.index, 1);
        this.toggleModal();
        if (typeof this.props.callback === 'function') this.props.callback(this.state.products);
    }

    changed = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const item = this.state.item;

        let increment = item.increment;
        let discount = item.discount;
        let charge = item.charge;
        let quantity = item.quantity;
        let price = item.price;
        let amount = (price * quantity);

        if (name === "increment" || name === "discount" || name === "charge" || name === "quantity") {
            if (name === "increment") {
                increment = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                charge = (amount + increment) >= 0 ? (amount + increment) : 0;
                discount = 0;
            } else if (name === "discount") {
                discount = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                if (discount > amount) {
                    discount = amount;
                }
                charge = (amount - discount) >= 0 ? (amount - discount) : 0;
                increment = 0;
            } else if (name === "charge") {
                charge = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                increment = (charge - amount);
                if (increment < 0) {
                    discount = (increment * -1);
                    increment = 0;
                } else {
                    discount = 0;
                }
            } else if (name === 'quantity') {
                quantity = value != '' ? Util.num(value, 0) : '';
                if (quantity > 0) {
                    if (discount > 0 && ((quantity * item.price) < discount)) {
                        discount = (quantity * item.price);
                    }
                    charge = (((quantity * item.price) + increment) - discount);
                } else {
                    quantity = '';
                    charge = 0;
                }
            }

            this.setState({
                item: {
                    ...this.state.item,
                    quantity: quantity,
                    charge: charge,
                    increment: increment,
                    discount: discount,
                },
                error: '',
            });
        } else {
            this.setState({
                item: {
                    ...this.state.item,
                    [name]: value,
                },
                error: '',
            });
        }
    };

    render() {
        const {item, products} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        Generar venta
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="bold">Información del producto</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Nombre</Label>
                                            <Input value={item.name} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Detalle</Label>
                                            <Input type='textarea'
                                                   rows={3}
                                                   value={item.detail} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Stock</Label>
                                            <Input value={item.stock} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label>Precio</Label>
                                            <Input value={User.stg.coin + ' ' + Util.num(item.price, 2)} disabled/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="bold">Venta</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Cantidad</Label>
                                            <Input name="quantity"
                                                   className="form-control"
                                                   type="number"
                                                   value={item.quantity}
                                                   onChange={this.changed}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label>Precio</Label>
                                            <Input disabled
                                                   value={User.stg.coin + ' ' + Util.num((item.quantity * item.price), 2)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {item.edit_price ?
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Incremento</Label>
                                                <Input name="increment"
                                                       className="form-control"
                                                       type="number"
                                                       value={item.increment}
                                                       onChange={this.changed}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Descuento</Label>
                                                <Input name="discount"
                                                       className="form-control"
                                                       type="number"
                                                       value={item.discount}
                                                       onChange={this.changed}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Nuevo precio</Label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{User.stg.coin}</span>
                                                    </div>
                                                    <Input name="charge"
                                                           className="form-control"
                                                           type="number"
                                                           value={item.charge}
                                                           onChange={this.changed}/>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    : <span></span>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.index >= 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.removeItem}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.addItem}>
                            <i className="fa fa-check"/> {this.state.index >= 0 ? 'Editar' : 'Agregar'}
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
