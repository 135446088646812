import './inc/polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Redirect, Route} from "react-router-dom";
import Switch from "react-router-dom/es/Switch";
import 'font-awesome/css/font-awesome.min.css';
import './scss/style.css'
import DefaultLayout from "./views/layouts/DefaultLayout";
import registerServiceWorker from "./inc/registerServiceWorker";
import Login from "./views/auth/Login";
import {User} from "./inc/user";

import 'moment/locale/es'
import 'react-dates/lib/css/_datepicker.css';

import moment from "moment";
import {ToastContainer} from "react-toastify";
import RecoverPassword from "./views/ext/RecoverPassword";

moment.locale('es');

User.init();

ReactDOM.render((
    <>
        <HashRouter>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/recover-password" component={RecoverPassword}/>
                {
                    User.logged() ? <Route path="/" component={DefaultLayout}/> : <Redirect to="./login"/>
                }
            </Switch>
        </HashRouter>
        <ToastContainer/>
    </>
), document.getElementById('root'));

registerServiceWorker();
