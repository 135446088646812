import React from 'react';
import {
    Button,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import {User} from "../inc/user";
import AppSwitch from "@coreui/react/es/Switch";

const STATE = {
    item: {
        id: 0,
        name: '',
        detail: '',
        stock: '0',
        cost: '0',
        price: '0',
        edit_price: '0',
        sort: '0'
    },
    loading: false,
    error: null,
    errors: {},
    modal: false,
    categories: []
};

export default class ProductsM extends _component {

    state = clone(STATE);

    add = () => {
        this.setState({
            ...STATE,
            loading: false,
            modal: true,
        });
    };

    edit = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/products/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    item: rsp.item,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        const item = {...this.state.item};
        const data = new FormData();
        Object.keys(item).map(key => data.append(key, item[key]));

        Api.post('/products/create', data, (rsp) => {

            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.toggleModal();
                if (typeof this.props.callback === 'function') this.props.callback({
                    ...this.state.item,
                    id: rsp.id
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg,
                    errors: rsp.errors
                });
            }
        });
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/products/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...');
        });
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} producto
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Nombre</Label>
                                            <Input name="name" value={item.name}
                                                   onChange={this.changed}
                                                   invalid={errors.name != null}/>
                                            <FormFeedback>{errors.name}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Detalle</Label>
                                            <Input type='textarea'
                                                   rows={2}
                                                   name="detail"
                                                   value={item.detail}
                                                   onChange={this.changed}
                                                   invalid={errors.detail != null}/>
                                            <FormFeedback>{errors.detail}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Costo</Label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">{User.stg.coin_name}</span>
                                                </div>
                                                <Input className="form-control"
                                                       type="number"
                                                       name="cost"
                                                       value={item.cost}
                                                       onChange={this.changed}
                                                       invalid={errors.cost != null}/>
                                                <FormFeedback>{errors.cost}</FormFeedback>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Precio</Label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">{User.stg.coin_name}</span>
                                                </div>
                                                <Input className="form-control"
                                                       type="number"
                                                       name="price"
                                                       value={item.price}
                                                       onChange={this.changed}
                                                       invalid={errors.price != null}/>
                                                <FormFeedback>{errors.price}</FormFeedback>
                                            </div>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Stock</Label>
                                            <Input name="stock" value={item.stock} onChange={this.changed}/>
                                            <FormFeedback>{errors.stock}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <div>Editar precio</div>
                                            <AppSwitch
                                                className="mdl"
                                                color="primary"
                                                variant="pill"
                                                checked={item.edit_price === '1'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        ...this.state,
                                                        item: {
                                                            ...this.state.item,
                                                            edit_price: e.target.checked ? '1' : '0'
                                                        }
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
