import React, {Component} from 'react';
import {
    Collapse, Button, CardBody, Card, Col, Input, Table
} from 'reactstrap';

import PropTypes from 'prop-types';
import {User} from "../../inc/user";
import Util from "../../inc/utils";

export default class ItemCollapse extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    state = {
        collapse: false,
    }

    toggle() {
        this.setState(state => ({collapse: !state.collapse}));
    }

    render() {
        const {item} = this.props;
        const collapse = this.state.collapse;

        const inputText = {
            'border': '0px solid',
            'padding': '0px',
            'borderRadius': '0px',
        };

        const td = {
            "verticalAlign": "middle",
            'padding': '0px 10px 0px 10px'
        };

        return (
            <tbody style={{'border': '0px solid black'}}>
            <tr style={{'background': collapse ? '#EFEFEF' : ''}}>
                <td style={td}>{item.user_name}</td>
                <td style={td}>{User.stg.coin + ' ' + Util.num(item.total, 2)}</td>
                <td className="text-primary"
                    style={td}>{item.cash > 0 ? User.stg.coin + ' ' + Util.num(item.cash, 2) : ''}
                </td>
                <td style={td}
                    className="text-danger">{item.remaining > 0 ? User.stg.coin + ' ' + Util.num(item.remaining, 2) : ''}
                </td>

                <td style={td} className="nowrap">{item.date_created.datetime()}</td>
                <td>
                    <Button color="primary" size="sm"
                            onClick={this.toggle}>
                        <i className={this.state.collapse ? "fa fa-chevron-up" : "fa fa-chevron-down"}/>
                    </Button>
                </td>
            </tr>

            {collapse && (
                <tr>
                    <td colSpan={6}>
                        <Collapse isOpen={collapse}>
                            <Table bordered size="sm" className="m-0" style={{'background': 'white'}}>
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Incremento</th>
                                    <th>Descuento</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    item.items.map((o, i) => (
                                        <tr>
                                            <td>{o.name}</td>
                                            <td>{o.quantity}</td>
                                            <td>{User.stg.coin + ' ' + Util.num(o.price, 2)}</td>
                                            <td>{o.increment > 0 ? User.stg.coin + ' ' + Util.num(o.increment, 2) : ''}</td>
                                            <td>{o.discount > 0 ? User.stg.coin + ' ' + Util.num(o.discount, 2) : ''}</td>
                                            <td>{User.stg.coin + ' ' + Util.num(o.charge, 2)}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Collapse>
                    </td>
                </tr>
            )}
            </tbody>
        );
    }
}


ItemCollapse.propTypes = {
    item: PropTypes.object
};
