import React from 'react';
import {
    Card, CardBody, CardHeader
} from 'reactstrap';
import Fils from "./widgets/fils";

export default class Logs extends React.Component {

    render() {
        return (
            <Card>
                <CardHeader>
                    <span className="text-uppercase bold">Logs</span>
                </CardHeader>
                <CardBody className="pdg-5">

                    <Fils {...this.props}
                          ref={ins => this.fils = ins}
                          endpoint={this.props.match.url}
                          cols={[
                              {name: 'id', value: '#', width: '1%'},
                              {name: 'desc', value: 'Descripción'},
                              {name: 'date_created', value: 'Fecha de creación', width: '1%'}
                          ]}
                          row={(o, i) => (
                              <tr key={i}>
                                  <td>{o.id}</td>
                                  <td style={{color: o.tg_color}}>
                                      {o.us_name}{' '}
                                      <em>{o.tg_type}</em>{' '}
                                      {o.tg_name}{' '}
                                      <b>{o.tg_value}</b>
                                  </td>
                                  <td className="nowrap">{o.date_created.datetime()}</td>
                              </tr>
                          )}
                    />

                </CardBody>
            </Card>)
    }
}