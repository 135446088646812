import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Collapse extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    state = {
        open: false,
    };

    toggle() {
        this.setState(state => ({open: !state.open}));
    }

    render() {
        const {props} = this;
        const {item, index} = this.props;
        const open = this.state.open;
        return (
            <tbody className="border-0">
            {this.props.row(item, index, open, this.toggle)}
            {open && typeof props.cols2 != 'undefined' && props.cols2(item)}
            {open && item.items.length > 0 && (item.items.map((o, i) => this.props.row2(o, i)))}
            </tbody>
        );
    }
}

Collapse.propTypes = {
    item: PropTypes.object
};
