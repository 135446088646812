import React from 'react';
import {
    Button, Card, CardBody, CardHeader
} from 'reactstrap';
import Fils from "./widgets/fils";
import ClientM from "./ClientsM";
import {User} from "../inc/user";

export default class Clients extends React.Component {

    state = {
        canEdit: User.can(this),
    };

    render() {
        return (
            <div>
                <Card className="_card">
                    <CardHeader>
                        <span className="text-uppercase bold">Clientes</span>
                        <div className="card-header-actions">
                            {this.state.canEdit && (
                                <button className="bold text-uppercase btn btn-primary"
                                        onClick={() => this.modal.add()}>
                                    <i className="fa fa-plus"/> Nuevo
                                </button>
                            )}
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">

                        <Fils {...this.props}
                              ref={ins => this.fils = ins}
                              endpoint={this.props.match.url}
                              fields={[
                                  {
                                      'type': 'range',
                                      'title': 'Filtro por fecha',
                                      'nameFrom': 'date_from',
                                      'nameTo': 'date_to',
                                  },
                              ]}
                              cols={[
                                  {value: '#', width: '1%'},
                                  {value: 'Nombre'},
                                  {value: 'Apellido'},
                                  {value: 'Documento'},
                                  {value: 'Teléfono'},
                                  {value: 'Correo electrónico'},
                                  {value: 'Fecha de creación', width: '1%'},
                                  {width: '1%'}
                              ]}
                              row={(o, i) => (
                                  <tr key={i}>
                                      <td>{o.id}</td>
                                      <td>{o.name}</td>
                                      <td>{o.surname}</td>
                                      <td>{o.document}</td>
                                      <td>{o.phone}</td>
                                      <td>{o.email}</td>
                                      <td className="nowrap">{o.date_created.datetime()}</td>
                                      <td>
                                          {this.state.canEdit && (
                                              <Button color="primary" size="sm"
                                                      onClick={() => this.modal.edit(o.id)}>
                                                  <i className="fa fa-pencil"/>
                                              </Button>
                                          )}
                                      </td>
                                  </tr>
                              )}
                        />

                    </CardBody>
                </Card>

                <ClientM ref={ins => this.modal = ins}
                         callback={() => this.fils.loadData()}/>

            </div>)
    }
}