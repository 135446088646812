import React from 'react';
import {
    Button, Card, CardBody, CardHeader
} from 'reactstrap';
import Fils from "./widgets/fils";
import PricesM from "./PricesM";
import {User} from "../inc/user";
import Util from "../inc/utils";

export default class Prices extends React.Component {
    state = {
        canEdit: User.can(this),
    };

    render() {
        return (
            <div>
                <Card className="mini _card">
                    <CardHeader>
                        <span className="text-uppercase bold">Precios</span>
                        <div className="card-header-actions">
                            {this.state.canEdit && (
                                <button className="bold text-uppercase btn btn-primary"
                                        onClick={() => this.modal.add()}>
                                    <i className="fa fa-plus"/> Nuevo
                                </button>
                            )}
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">

                        <Fils {...this.props}
                              ref={ins => this.fils = ins}
                              endpoint={this.props.match.url}
                              cols={[
                                  {value: '#', width: '1%'},
                                  {value: 'Horas'},
                                  {value: 'Precio'},
                                  {value: 'Fecha de creación', width: '1%'},
                                  {width: '1%'}
                              ]}
                              row={(o, i) => (
                                  <tr key={i}>
                                      <td>{o.id}</td>
                                      <td>{o.hours}</td>
                                      <td className="nowrap">{User.stg.coin} {Util.num(o.price, 2)}</td>
                                      <td className="nowrap">{o.date_created.datetime()}</td>
                                      <td>
                                          {this.state.canEdit && (
                                              <Button color="primary" size="sm"
                                                      onClick={() => this.modal.edit(o.id)}>
                                                  <i className="fa fa-pencil"/>
                                              </Button>
                                          )}
                                      </td>
                                  </tr>
                              )}
                        />
                    </CardBody>
                </Card>

                <PricesM ref={ins => this.modal = ins}
                         callback={() => this.fils.loadData()}/>
            </div>)
    }
}