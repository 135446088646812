import React from 'react';
import {Card, CardBody, CardHeader, Row} from 'reactstrap';
import ButtonState from "./widgets/button_state";
import OccupyRoomM from "./OccupyRoomM";
import CleaningRoomM from "./CleaningRoomM";
import ActiveRoomM from "./ActiveRoomM";
import {User} from "../inc/user";
import {Api} from "../inc/api";
import {BarLoader} from "react-spinners";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import Col from "reactstrap/es/Col";

export default class Tosell extends React.Component {

    state = {
        canEdit: User.can(this),
        state: '',
        items: [],
        loading: true,
        loadingButton: true,
        error: null
    };

    getBedrooms() {
        Api.get('/reception', {state: 2}, (rsp) => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    loadingButton: false,
                    item: rsp.item,
                    items: rsp.items
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    loadingButton: false,
                    error: rsp.msg
                });
            }
        }, false);
    }

    componentWillMount() {
        this.getBedrooms('');
        this.myInterval = setInterval(() => {
            this.getBedrooms();
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    render() {
        const {items} = this.state;
        return (
            <div>
                <Card className="_card">
                    <CardHeader>
                        <span className="text-uppercase bold">Punto de venta</span>
                        <div className="card-header-actions">
                            {this.state.loadingButton ?
                                <button className="text-uppercase btn btn-info"
                                        onClick={() => this.getBedrooms("refresh")}>
                                    <i className="fa fa-refresh"/>
                                </button> :
                                <button className="text-uppercase btn btn-primary"
                                        onClick={() => this.getBedrooms("refresh")}>
                                    <i className="fa fa-refresh"/>
                                </button>
                            }
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">
                        <span hidden={!this.state.loading}>
                            <BarLoader width={-1} height={3}/>
                            <div className="mb-1"></div>
                        </span>

                        <Row>
                            {items.map((o, i) => (
                                <Col md={3}>
                                    <div className="card">
                                        <div className="card-body p-0 d-flex align-items-center">
                                            <i className="fa fa-shopping-basket bg-info p-4 font-2xl mr-3"></i>
                                            <div>
                                                <div className="text-value-sm text-info">{o.name}</div>
                                                <div className="text-muted text-uppercase font-weight-bold small">Vender
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </CardBody>
                </Card>

                <OccupyRoomM ref={ins => this.occupyroom = ins}
                             callback={() => this.getBedrooms("refresh")}/>

                <CleaningRoomM ref={ins => this.cleaningroom = ins}
                               callback={() => this.getBedrooms("refresh")}/>

                <ActiveRoomM ref={ins => this.activeroom = ins}
                             callback={() => this.getBedrooms("refresh")}/>
            </div>)
    }
}