import React, {Component} from 'react';
import {
    Alert,
    Button,
    Form, FormGroup,
    Input, Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Row,
    Col
} from 'reactstrap';
import {Api} from "../../inc/api";
import * as queryString from "query-string";
import {BarLoader} from "react-spinners";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import RangePicker from "./range_picker";
import Collapse from "./collapse";

export default class Fils extends Component {

    constructor(props) {
        super(props);

        let fils = {
            page: 1,
            word: '',
        };

        if (Array.isArray(this.props.fields)) {
            this.props.fields.forEach(field => {
                if (field.type === "range") {
                    fils[field.nameFrom] = '';
                    fils[field.nameTo] = '';
                } else {
                    fils[field.name] = '';
                }
            });
        }

        let params = queryString.parse(props.location.search);
        Object.keys(params).map((k) => fils[k] = params[k]);

        // Estado inicial
        this.state = {
            loading: false,
            fils: fils,
            items: []
        };
    }

    clickPrev = () => {
        let _state = this.state;
        _state.fils.page = --_state.fils.page;
        this.setState(_state);

        this.loadData();
    };

    clickNext = () => {
        let _state = this.state;
        _state.fils.page = ++_state.fils.page;
        this.setState(_state);

        this.loadData();
    };

    loadData() {

        this.props.history.push({
            search: '?' + queryString.stringify(this.state.fils)
        });

        this.setState({
            loading: true
        });

        Api.get(this.props.endpoint, this.state.fils, rsp => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    items: rsp.items
                });
                this.props.state(rsp);
            } else {
                this.setState({
                    loading: false,
                });
            }

        });
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange = (event) => {
        this.setState({
            fils: {
                ...this.state.fils,
                [event.target.name]: event.target.value
            }
        });
    };

    clickColumn = (o) => {
        if (o.name) {
            //console.log('this.clickColumn', o);
        }
    };

    clickRemove = (id) => {
        if (window.confirm('¿Seguro que quieres borrar?')) {
            Api.get(this.props.endpoint + '/remove', {id: id}, (rsp) => {
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.loadData();
                } else {
                    toast.error('Se produjo un error');
                }
            }, 'Eliminando...')
        }
    };

    callbackRangePicker = (rsp, nameFrom, nameTo) => {
        this.setState({
            fils: {
                ...this.state.fils,
                [nameFrom]: rsp.from,
                [nameTo]: rsp.to,
            }
        });
    };

    submit = async (e) => {
        e.preventDefault();
        await this.setState({
            fils: {
                ...this.state.fils,
                page: 1
            }
        });
        this.loadData();
    };

    render() {
        const {props} = this;
        const {fils} = this.state;

        return (
            <div>
                <Form inline className="pb-2 pt-2"
                      hidden={typeof props.no_filters === 'boolean' && props.no_filters}
                      onSubmit={this.submit}>

                    {typeof props.form === 'function' ? props.form() : ''}

                    {!props.notFilterWord && (
                        <FormGroup className="mb-2 mr-2">
                            <Col className="p-0">
                                <div>Búsqueda</div>
                                <Input name="word"
                                       value={fils.word}
                                       onChange={this.handleChange}
                                       placeholder="Buscar..."/>
                            </Col>
                        </FormGroup>
                    )}

                    {Array.isArray(props.fields) ? props.fields.map((o, i) => {
                        switch (o.type) {
                            case 'select':
                                return (
                                    <FormGroup className="mb-2 mr-2" key={i}>
                                        <Col className="p-0">
                                            <div className="mr-sm-2">{o.title}</div>
                                            <Input name={o.name}
                                                   type="select"
                                                   value={fils[o.name]}
                                                   onChange={this.handleChange}
                                                   placeholder="Buscar...">
                                                <option value="">Elegir...</option>
                                                {o.items.map((itm, i2) => <option key={i2} value={itm.id}>
                                                    {itm.name}</option>)}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                );
                            case 'select2': // select 2 ajax
                                return (
                                    <FormGroup className="mb-2 mr-2" key={i}>
                                        <Col className="p-0">
                                            <div>{o.title}</div>
                                            <AsyncSelect
                                                cacheOptions
                                                defaultOptions
                                                isClearable
                                                className="react-select-fils"
                                                classNamePrefix="react-select"
                                                placeholder="Buscar..."
                                                loadOptions={(word, callback) => {
                                                    Api.get(o.endpoint, {word}, rsp => {
                                                        let items = [];
                                                        if (rsp.ok) {
                                                            rsp.items.map((o) => items.push({
                                                                value: o.id,
                                                                label: o.name
                                                            }));
                                                        }
                                                        callback(items);
                                                    });
                                                }}
                                                onChange={(option) => {
                                                    this.setState({
                                                        fils: {
                                                            ...fils,
                                                            [o.name]: option ? option.value : 0
                                                        }
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </FormGroup>
                                );
                            case 'date':
                                return (
                                    <FormGroup key={i} className="mb-2 mr-2">
                                        <Col className="p-0">
                                            <div>{o.title}</div>
                                            <Input name={o.name}
                                                   value={this.state.fils[o.name]}
                                                   type="date"
                                                   onChange={this.handleChange}/>
                                        </Col>
                                    </FormGroup>
                                );
                            case 'range':
                                return (
                                    <FormGroup key={i} className="mb-2 mr-2">
                                        <Col md={12} className="p-0">
                                            <div>{o.title}</div>
                                            <RangePicker
                                                callback={(rsp) => this.callbackRangePicker(rsp, o.nameFrom, o.nameTo)}
                                                fils={true}
                                                name={true}
                                            />
                                        </Col>
                                    </FormGroup>
                                );
                            default:
                                break
                        }
                    }) : ''}

                    <FormGroup className="mb-2 mr-2">
                        <Col className="p-0">
                            <div>&nbsp;</div>
                            <Button color="primary">Aplicar</Button>
                        </Col>
                    </FormGroup>

                </Form>

                <div hidden={!this.state.loading}
                     style={{marginBottom: '-4px', background: 'white', position: 'relative', zIndex: 3}}>
                    <BarLoader width={-1}/>
                </div>

                <Table
                    bordered={typeof props.bordered === 'undefined' ? true : props.bordered}
                    borderless={typeof props.bordered === 'undefined' ? false : props.bordered}
                    striped={typeof props.striped === 'undefined' ? true : props.striped}
                    responsive
                    className="mdl-td _tbl mrg-0"
                    id="table-fils">
                    <thead>
                    <tr>
                        {props.cols.map((_o, i) => {

                            let o = Object.assign({
                                name: '',
                                value: '',
                                width: '',
                                className: ''
                            }, _o);

                            if (o.width === '1%') o.className += ' nowrap';

                            return (
                                <th
                                    width={o.width}
                                    key={i}
                                    className={o.className}
                                    onClick={() => this.clickColumn(o)}>
                                    {o.value}
                                </th>
                            )
                        })}
                    </tr>

                    </thead>
                    {!this.props.collapse && (
                        <tbody>
                        {this.state.items.map(typeof props.row === 'function' ? props.row : (o, i) => (
                            <tr key={i}>
                                {props.cols.map((_o, i) => {
                                    switch (_o.name) {
                                        case '__remove':
                                            return (
                                                <td key={i}>
                                                    <button onClick={() => this.clickRemove(o.id)}
                                                            className="btn btn-danger mrg-0 btn-sm">
                                                        <i className="fa fa-trash"/>
                                                    </button>
                                                </td>
                                            );
                                        case '__edit':
                                            return (
                                                <td key={i}>
                                                    <Link to={props.endpoint + "/form/" + o.id}
                                                          className="btn btn-primary btn-sm">
                                                        <i className="fa fa-pencil"/>
                                                    </Link>
                                                </td>
                                            );
                                        default:
                                            return (
                                                <td key={i}>
                                                    {o[_o.name]}
                                                </td>
                                            );
                                    }
                                })}
                            </tr>
                        ))}

                        {!this.state.loading && fils.page == 1 && this.state.items.length == 0 && (
                            <tr>
                                <td colSpan="100%" className="pdg-15" style={{backgroundColor: 'white'}}>
                                    <Alert color="warning" className="mrg-0">
                                        No hay resultados
                                    </Alert>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    )}

                    {this.props.collapse && this.state.items.map((o, i) => (
                        <Collapse
                            {...this.props}
                            item={o}
                            index={i}
                            row={this.props.row}
                            cols2={this.props.cols2}
                            row2={this.props.row2}
                        />
                    ))}

                    {this.props.collapse && !this.state.loading && fils.page == 1 && this.state.items.length == 0 && (
                        <tbody>
                        <tr>
                            <td colSpan="100%" className="pdg-15" style={{backgroundColor: 'white'}}>
                                <Alert color="warning" className="mrg-0">
                                    No hay resultados
                                </Alert>
                            </td>
                        </tr>
                        </tbody>
                    )}
                </Table>

                <div hidden={
                    (typeof props.no_pager === 'boolean' && props.no_pager)
                    || (!this.state.loading && fils.page == 1 && this.state.items.length == 0)
                }>
                    <Pagination className="mrg-t-10">
                        {fils.page > 1 && (
                            <PaginationItem>
                                <PaginationLink onClick={this.clickPrev} previous tag="button"/>
                            </PaginationItem>
                        )}
                        <PaginationItem active>
                            <PaginationLink tag="button">{fils.page}</PaginationLink>
                        </PaginationItem>
                        {this.state.items.length > 0 && (
                            <PaginationItem>
                                <PaginationLink onClick={this.clickNext} next tag="button"/>
                            </PaginationItem>
                        )}
                    </Pagination>
                </div>
            </div>
        );
    }
}

Fils.propTypes = {
    fields: PropTypes.array,
    collapse: PropTypes.bool,
    cols: PropTypes.array,
    no_filters: PropTypes.bool,
    no_pager: PropTypes.bool,
    notFilterWord: PropTypes.bool,
    state: PropTypes.func,
};
