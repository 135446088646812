import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row,
    Form
} from 'reactstrap';

import {Api} from "../../inc/api";
import {User} from "../../inc/user";
import {Alert} from "reactstrap/lib/index";
import {Link} from "react-router-dom";

export default class Login extends Component {

    state = {
        username: '',
        password: '',
        loading: false,
        error: null,
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    login = (event) => {
        event.preventDefault();

        this.setState({
            loading: true,
            error: null
        });

        const data = {
            username: this.state.username,
            password: this.state.password
        };

        Api.get('/login/login', data, (rsp) => {
            if (rsp.ok) {

                User.token = rsp.token;
                User.verify(function () {
                    window.location.href = './';
                });

            } else {
                this.setState({
                    loading: false,
                    error: rsp.msg
                });
            }
        });

    };

    render() {
        return (
            <section id="home-section" className="main-banner active">
                <div className="bg-gradient">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8">
                                <CardGroup>
                                    <Card className="p-4 cardLogin">
                                        <div className="_login-title">
                                            Iniciar sesión
                                        </div>

                                        <div hidden={this.state.error == null} className="_alert mt-3">
                                            {this.state.error}
                                        </div>

                                        <Form onSubmit={this.login} className="mt-3">
                                            <InputGroup className="_separator-border">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className="_input-icon-user">
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text"
                                                       placeholder="Usuario"
                                                       name="username"
                                                       value={this.state.username}
                                                       onChange={this.handleChange}
                                                       autoFocus
                                                       className="_input-user"/>
                                            </InputGroup>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className="_input-icon-user">
                                                        <i className="fa fa-lock"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password"
                                                       placeholder="Contraseña"
                                                       name="password"
                                                       value={this.state.password}
                                                       onChange={this.handleChange}
                                                       className="_input-pass"/>
                                            </InputGroup>
                                            <div className="text-center">
                                                <button type="submit" className="_button-login pointer">
                                                    Ingresar
                                                </button>
                                            </div>
                                        </Form>
                                    </Card>
                                    <Card className="cardLogin py-5 px-2 d-md-down-none" style={{width: 44 + '%'}}>
                                        <div>
                                            <img src="img/logo-login.png"
                                                 style={{maxWidth: '100%', marginTop: 80, marginLeft: 120}}
                                                 alt="logo"/>
                                        </div>
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        );
    }

}
