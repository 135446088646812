import moment from "moment";

const $loading = document.getElementById('loading');

export default class Util {

    static loading(show) {
        if (show) {

            if (typeof show === 'string') {
                $loading.innerHTML = show;
            } else {
                $loading.innerHTML = 'Cargando...';
            }
            $loading.style.display = 'block';

        } else {
            $loading.style.display = 'none';
        }

    }

    static time() {
        return parseInt(new Date().getTime() / 1000, 10);
    }

    // Convertir cualquier cosa a numero
    static num(str, decimals, defult) {
        let n = Number(str),
            df = (typeof defult === 'number') ? defult : 0;
        n = isNaN(n) || n == 0 ? df : n;
        return (typeof decimals === 'number' && decimals >= 0) ? n.toFixed(decimals) : n;
    }

    static pad(num, size) {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    static date() {
        return moment().format('DD/MM/Y');
    }
}

// protos
String.prototype.date = function () {
    return this ? moment(this).format('DD/MM/Y') : '';
};

String.prototype.datetime = function () {
    return this ? moment(this).format('DD/MM/Y hh:mm A') : '';
};
