import React from 'react';
import {
    Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import clone from "clone-deep";
import Util from "../inc/utils";
import InpurSearch from "./widgets/input_search";

const CLIENT = {
    id: 0,
    name: '',
    surname: '',
    document: '',
    birthdate: '',
    phone: '',
    gender: '0',
    email: '',
}

const STATE = {
    item: {
        id: 0,
        name: '',
        surname: '',
        document: '',
        birthdate: '',
        phone: '',
        gender: '0',
        email: '',
    },
    index: -1,
    companions: [],
    loading: false,
    error: null,
    errors: {},
    modal: false,
}

export default class CompanionsM extends _component {

    state = clone(STATE);

    add = (item, companions) => {
        if (typeof companions === 'undefined') {
            companions = item;
            item = CLIENT;
        }

        let index = -1;

        companions.map((o, i) => {
            if ((o.id != 0) && o.id == item.id) {
                index = i;
                item = o;
            }

            if ((o.id === 0) && o.document == item.document) {
                index = i;
                item = o;
            }
        });

        this.setState({
            ...clone(STATE),
            item: item,
            companions: companions,
            index: index,
            modal: true,
        });
    };


    addItem = () => {
        var errors = new Object();
        let modal = true;
        const item = this.state.item;

        if (item.document == '') {
            errors.document = 'El campo documento es necesario';
        }

        if (item.name == '') {
            errors.name = 'El campo Nombre es necesario';
        }

        if (item.surname == '') {
            errors.surname = 'El campo Apellido es necesario';
        }

        console.log(Object.keys(errors));
        console.log(item.document);

        if (Object.keys(errors).length === 0) {
            console.log("Sin errores");
            if (this.state.index >= 0) {
                this.state.companions.splice(this.state.index, 1, this.state.item);
            } else {
                this.state.companions.push(this.state.item);
            }
            modal = false;
            if (typeof this.props.callback === 'function') this.props.callback(this.state.companions);
        }

        this.setState({
            ...this.state,
            errors: errors,
            modal: modal,
        });
    };

    removeItem = () => {
        this.state.companions.splice(this.state.index, 1);
        this.toggleModal();
        if (typeof this.props.callback === 'function') this.props.callback(this.state.companions);
    };

    callbackDocument = async (o) => {
        console.log('callbackDocument: ', o);
        if (o.stateItem) {
            await this.setState({
                ...this.state,
                item: o.item,
            });

            this.setState({
                item: {
                    ...this.state.item,
                    document: o.inputValue
                }
            });
        } else {
            await this.setState({
                ...this.state,
                item: CLIENT,
            });
            this.setState({
                item: {
                    ...this.state.item,
                    document: o.inputValue
                }
            });
        }
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggleModal}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} cliente
                    </ModalHeader>
                    <ModalBody className="form-horizontal _frm">

                        <div className="alert alert-danger" hidden={!this.state.error}>
                            {this.state.error}
                        </div>

                        <FormGroup row>
                            <Col md="6">
                                <p>Documento:</p>
                                <InpurSearch
                                    endpoint='/reception/search'
                                    inputValue={item.document}
                                    active='document'
                                    title='name'
                                    subtitle='document'
                                    item={item}
                                    invalid={errors.document}
                                    callback={this.callbackDocument}/>
                            </Col>

                            <Col sm="6">
                                <Label>Teléfono</Label>
                                <Input name="phone" value={item.phone} onChange={this.changed}
                                       invalid={errors.phone != null}/>
                                <FormFeedback>{errors.phone}</FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col sm="6">
                                <Label>Nombre</Label>
                                <Input name="name" value={item.name}
                                       className="text-capitalize"
                                       onChange={this.changed}
                                       invalid={errors.name != null}/>
                                <FormFeedback>{errors.name}</FormFeedback>
                            </Col>
                            <Col sm="6">
                                <Label>Apellido</Label>
                                <Input name="surname" value={item.surname}
                                       className="text-capitalize"
                                       onChange={this.changed}
                                       invalid={errors.surname != null}/>
                                <FormFeedback>{errors.surname}</FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col sm="4">
                                <Label>Correo electrónico</Label>
                                <Input name="email" value={item.email} onChange={this.changed}
                                       invalid={errors.email != null}/>
                                <FormFeedback>{errors.email}</FormFeedback>
                            </Col>
                            <Col sm="4">
                                <Label>Fecha de nacimiento</Label>
                                <div className="input-group">
                                    <span className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fa fa-calendar"></i>
                                        </span>
                                    </span>
                                    <Input name="birthdate"
                                           value={item.birthdate}
                                           onChange={this.changed}
                                           invalid={errors.birthdate != null}
                                           type="date"/>
                                    <FormFeedback>{errors.birthdate}</FormFeedback>
                                </div>
                            </Col>
                            <Col sm="4">
                                <Label>Género</Label>
                                <Input name="gender" value={item.gender} onChange={this.changed}
                                       invalid={errors.gender != null} type="select">
                                    <option value="0">Elegir...</option>
                                    <option value="1">Hombre</option>
                                    <option value="2">Mujer</option>
                                </Input>
                                <FormFeedback>{errors.gender}</FormFeedback>
                            </Col>
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        {this.state.index >= 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.removeItem}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.addItem}>
                            <i className="fa fa-check"/> Agregar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
