import React from 'react';
import {
    Button,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import {User} from "../inc/user";
import Util from "../inc/utils";
import AsyncSelect from "react-select/lib/Async";

const STATE = {
    bedroom: {
        id: 0,
        name: '',
        detail: '',
        category: '',
        price: '',
        hours: '',
        pricexhour: '',
        price_hour: '',
        edit_price: '0',
    },
    client: {
        id: 0,
        name: '',
        surname: '',
        document: '',
        birthdate: '',
        phone: '',
        gender: '0',
        email: '',
    },
    rate: {
        hours: '',
        price: '',
        subtotal: '0',
        increment: '0',
        discount: '0',
        total: '0',
        cash: '0',
        remaining: '0',
        charge: '0',
    },
    loading: false,
    error: null,
    errors: {},
    modal: false,
}

const CLIENT = {
    id: 0,
    name: '',
    surname: '',
    document: '',
    birthdate: '',
    phone: '',
    gender: '0',
    email: '',
}

export default class BedroomsM extends _component {

    state = clone(STATE);

    add = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/reception/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                let total = (Util.num(rsp.bedroom.price_hour) + Util.num(rsp.bedroom.increment)) - Util.num(rsp.bedroom.discount);
                let price = (Util.num(rsp.bedroom.hours) * total);
                this.setState({
                    loading: false,
                    bedroom: rsp.bedroom,
                    client: rsp.client,
                    rate: {
                        ...this.state.rate,
                        hours: rsp.bedroom.hours,
                        price: price,
                        charge: price,
                        subtotal: price,
                        total: price
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    changedClient = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            client: {
                ...this.state.client,
                [name]: value
            }
        });
    };

    changedRate = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const bedroom = this.state.bedroom;
        const rate = this.state.rate;

        let hours = Util.num(rate.hours);
        let price = Util.num(rate.price);
        let charge = Util.num(rate.charge);
        let increment = Util.num(rate.increment);
        let discount = Util.num(rate.discount);

        if (name === "increment" || name === "discount" || name === "charge" || name === "hours") {
            if (name === "increment") {
                increment = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                charge = (price + increment) >= 0 ? Util.num((price + increment), 2) : 0;
                discount = 0;
            } else if (name === "discount") {
                discount = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                if (discount > price) {
                    discount = price;
                }
                charge = (price - discount) >= 0 ? Util.num((price - discount), 2) : 0;
                increment = 0;
            } else if (name === "charge") {
                charge = value != '' ? value > 0 ? Util.num(value) : 0 : '';
                increment = (charge - price);
                if (increment < 0) {
                    discount = (increment * -1);
                    increment = 0;
                } else {
                    discount = 0;
                }
            } else if (name === 'hours') {
                hours = value != '' ? value > 0 ? Util.num(value, 0) : 0 : '';

                if (hours > 0) {
                    price = Util.num(hours * bedroom.price_hour);

                    if (discount > 0 && (price < discount)) {
                        discount = price;
                    }

                    charge = Util.num(((Util.num(price) + Util.num(increment)) - Util.num(discount)), 2);
                } else {
                    price = 0;
                    charge = 0;
                }
            }

            let total = (Util.num(price) + Util.num(increment)) - Util.num(discount);

            this.setState({
                rate: {
                    ...this.state.rate,
                    hours: hours,
                    price: price,
                    charge: charge,
                    increment: increment,
                    discount: discount,
                    subtotal: price,
                    total: total,
                },
                error: '',
            });
        } else {
            this.setState({
                rate: {
                    ...this.state.rate,
                    [name]: value,
                },
                error: '',
            });
        }
    };


    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        const rate = {...this.state.rate};
        const data = new FormData();
        let remaining = (Util.num(rate.total) - Util.num(rate.cash));
        data.append('report', this.state.bedroom.active);


        Object.keys(rate).map((key) => {
            let value = rate[key];

            switch (key) {
                case 'remaining':
                    value = remaining;
                    break;
                case 'cash':
                    value = value >= rate.total ? rate.total : value;
                    break;
                default:
                    value = value == '' ? 0 : value;
            }

            data.append(key, value)
        });

        if (remaining > 0) {
            this.setState({
                ...this.state,
                loading: false
            });

            let amount = '<span class="text-danger bold">' + User.stg.coin + ' ' + Util.num(remaining, 2) + '</span>';
            let message = 'Falta cobrar ' + amount + ', se le cobrara al desocupar la habitación.';

            Alert.confirm(message, () => {
                Alert.loading();
                Api.post('/reception/addtime', data, (rsp) => {
                    Alert.loading(false);
                    if (rsp.ok) {
                        toast.success('Guardado correctamente');
                        this.toggleModal();
                        if (typeof this.props.callback === 'function') this.props.callback();
                    } else {
                        Alert.error(rsp.msg);
                    }
                }, 'Guardando...');
            });
        } else {
            Api.post('/reception/addtime', data, (rsp) => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Guardado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            }, 'Guardando...');
        }
    };

    render() {
        const {bedroom, client, rate, errors} = this.state;
        const turned = (rate.cash - rate.charge);
        const colorBlue = '#20a8d8';
        const classBetween = 'btn-block d-flex justify-content-between align-items-center';
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="md">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        Agregar tiempo
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={12}>
                                <div className="card">
                                    <div className="card-header">
                                        <span className="bold">Datos del alojamiento</span>
                                    </div>
                                    <div className="card-body p-2">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Horas</Label>
                                                    <Input name="hours"
                                                           className="form-control"
                                                           type="number"
                                                           value={rate.hours}
                                                           onChange={this.changedRate}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Precio</Label>
                                                    <Input value={Util.num(rate.price, 2)} disabled/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {bedroom.edit_price && (
                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label>Incremento</Label>
                                                        <Input name="increment"
                                                               className="form-control"
                                                               type="number"
                                                               value={rate.increment}
                                                               onChange={this.changedRate}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label>Descuento</Label>
                                                        <Input name="discount"
                                                               className="form-control"
                                                               type="number"
                                                               value={rate.discount}
                                                               onChange={this.changedRate}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Nuevo precio</Label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                    <span
                                                        className="input-group-text">{User.stg.coin}</span>
                                                            </div>
                                                            <Input name="charge"
                                                                   className="form-control"
                                                                   type="number"
                                                                   value={rate.charge}
                                                                   onChange={this.changedRate}/>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>)}
                                    </div>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="card">
                                    <div className="card-header inputSimpleText">
                                        <b>Facturación</b>
                                    </div>
                                    <div className="card-body p-2">
                                        <div className={classBetween}>
                                                <span style={{}}>
                                                    Subtotal
                                                </span>
                                            <span style={{
                                                'color': colorBlue
                                            }}>
                                                    {User.stg.coin + ' ' + Util.num(rate.subtotal, 2)}
                                                </span>
                                        </div>

                                        {rate.increment > 0 && (
                                            <div className={classBetween}>
                                                    <span style={{}}>
                                                        Incremento
                                                    </span>
                                                <span style={{
                                                    'color': colorBlue
                                                }}>
                                                        {User.stg.coin + ' ' + Util.num(rate.increment, 2)}
                                                    </span>
                                            </div>
                                        )}

                                        {rate.discount > 0 && (
                                            <div className={classBetween}>
                                                <span style={{}}>
                                                    Descuento
                                                </span>
                                                <span style={{
                                                    'color': colorBlue
                                                }}>
                                                    {User.stg.coin + ' ' + Util.num(rate.discount, 2)}
                                                </span>
                                            </div>
                                        )}

                                        <div className={classBetween}>
                                                <span className="bold">
                                                    Total
                                                </span>
                                            <span className="bold text-primary"
                                                  onClick={() => {
                                                      this.setState({
                                                          rate: {
                                                              ...this.state.rate,
                                                              cash: Util.num(this.state.rate.total, 2)
                                                          }
                                                      })
                                                  }}>
                                                    {User.stg.coin + ' ' + Util.num(rate.total, 2)}
                                                </span>
                                        </div>
                                        <hr/>
                                        <div className={classBetween}>
                                            <FormGroup>
                                                <Label>Efectivo</Label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                                <span
                                                                    className="input-group-text">{User.stg.coin}</span>
                                                    </div>
                                                    <Input type="number"
                                                           name="cash"
                                                           value={rate.cash}
                                                           onChange={this.changedRate}/>
                                                </div>
                                            </FormGroup>
                                            <div className="m-2"/>
                                            <FormGroup>
                                                <Label className={turned < 0 ? 'text-danger' : 'text-success'}>
                                                    {turned < 0 ? 'Falta cobrar: ' : 'Vuelto: '}
                                                </Label>
                                                <Input
                                                    className={turned < 0 ? 'text-danger' : 'text-success'}
                                                    value={User.stg.coin + ' ' + Util.num(turned < 0 ? (turned * -1) : turned, 2)}/>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
