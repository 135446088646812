import React from 'react';
import {
    Button, Card, CardBody, CardHeader
} from 'reactstrap';
import Fils from "./widgets/fils";
import CategoriesM from "./CategoriesM";
import {User} from "../inc/user";

export default class Categories extends React.Component {

    state = {
        canEdit: User.can(this),
    };

    render() {
        return (
            <div>
                <Card className="mini _card">
                    <CardHeader>
                        <span className="text-uppercase bold">Categorias</span>
                        <div className="card-header-actions">
                            {this.state.canEdit && (
                                <button className="bold text-uppercase btn btn-primary"
                                        onClick={() => this.modal.add()}>
                                    <i className="fa fa-plus"/> Nuevo
                                </button>
                            )}
                        </div>
                    </CardHeader>
                    <CardBody className="pdg-5">
                        <Fils {...this.props}
                              ref={ins => this.fils = ins}
                              endpoint={this.props.match.url}
                              collapse={true}
                              striped={false}
                              fields={[
                                  {
                                      'type': 'range',
                                      'title': 'Filtro por fecha',
                                      'nameFrom': 'date_from',
                                      'nameTo': 'date_to',
                                  },
                              ]}
                              cols={[
                                  {value: '', width: '1%'},
                                  {value: 'Nombre'},
                                  {value: 'Fecha de creación', width: '1%'},
                                  {width: '1%'}
                              ]}

                              row={(o, i, collapse, toggle) => (
                                  <tr style={{'background': (i % 2) || collapse ? '#F0F3F5' : ''}}>
                                      <td>
                                          {o.items.length > 0 && (
                                              <Button outline color="info" size="sm" className="mr-0"
                                                      onClick={toggle}>
                                                  <i className={collapse ? 'fa fa-minus' : 'fa fa-plus'}/>
                                              </Button>
                                          )}
                                      </td>
                                      <td>{o.name}</td>
                                      <td className="nowrap">{o.date_created.datetime()}</td>
                                      <td className="mr-0">
                                          {this.state.canEdit && (
                                              <Button color="primary" size="sm" className="mr-0"
                                                      onClick={() => this.modal.edit(o.id)}>
                                                  <i className="fa fa-pencil"/>
                                              </Button>
                                          )}
                                      </td>
                                  </tr>
                              )}

                              row2={(o, i) => (
                                  <tr>
                                      <td className="border-bottom-0 border-right-0 border-top-0"></td>
                                      <td className="border-0">{o.name}</td>
                                      <td className="border-0 nowrap">{o.date_created.datetime()}</td>
                                      <td className="border-bottom-0 border-left-0 border-top-0 mr-0">
                                          {this.state.canEdit && (
                                              <Button outline color="info" size="sm" className="mr-0"
                                                      onClick={() => this.modal.edit(o.id)}>
                                                  <i className="fa fa-pencil"/>
                                              </Button>
                                          )}
                                      </td>
                                  </tr>
                              )}
                        />
                    </CardBody>
                </Card>

                <CategoriesM
                    ref={ins => this.modal = ins}
                    callback={() => this.fils.loadData()}
                />

            </div>)
    }
}