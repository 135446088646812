import React from 'react';
import {
    Button,
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    Table
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import AppSwitch from "@coreui/react/es/Switch";
import AsyncSelect from 'react-select/lib/Async';
import {User} from "../inc/user";
import Util from "../inc/utils";
import Fils from "./widgets/fils";

const STATE = {
    item: {
        id: 0,
        name: '',
        detail: '',
        sort: '0',
        price: '',
        hours: '',
        price_hour: '',
        edit_price: '0',
        type_services: [],
        categories: [],
    },
    price_hour: '',
    loading: false,
    error: null,
    errors: {},
    modal: false,
    categories: []
};

export default class BedroomsM extends _component {

    state = clone(STATE);

    add = () => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/bedrooms/form', {id: 0}, (rsp) => {
            if (rsp.ok) {
                let options = [];

                rsp.categories.map((o, i) => {
                    let option = new Object();
                    option.category = o.id;
                    option.value = 0;
                    options.push(option);
                });

                this.setState({
                    loading: false,
                    item: {
                        ...this.state.item,
                        categories: options
                    },
                    categories: rsp.categories
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    edit = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/bedrooms/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    item: rsp.item,
                    categories: rsp.categories
                });

                let options = [];

                rsp.categories.map((o, i) => {
                    let index = -1;
                    let option = new Object();
                    option.category = o.id;
                    rsp.item.categories.map((_o, i) => {
                        if (o.id == _o.category) {
                            let exists = false;
                            o.items.map((o) => {
                                if (o.id == _o.value) {
                                    index = i;
                                    option.value = _o.value;
                                }
                            });
                        }
                    });

                    if (index == -1) {
                        option.value = 0;
                    }

                    options.push(option);
                });
                this.setState({
                    item: {
                        ...this.state.item,
                        categories: options
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        const item = {...this.state.item};
        const data = new FormData();
        item.categories = JSON.stringify(item.categories);
        item.type_services = JSON.stringify(item.type_services.map(o => o.id));
        Object.keys(item).map(key => data.append(key, item[key]));

        Api.post('/bedrooms/create', data, (rsp) => {
            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.toggleModal();
                if (typeof this.props.callback === 'function') this.props.callback({
                    ...this.state.item,
                    id: rsp.id
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg,
                    errors: rsp.errors
                });
            }
        });
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/bedrooms/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...');
        });
    };

    getIndex = (category) => {
        let categories = this.state.item.categories;
        let index = -1;
        categories.map((o, i) => {
            if (o.category == category.id) {
                return index = i;
            }
        });
        return index;
    };

    render() {
        const {item, errors} = this.state;
        const {categories} = this.state;
        const price_hour = User.stg.coin + ' ' + Util.num((this.state.item.price / this.state.item.hours), 2);
        const price_hour_val = Util.num((this.state.item.price / this.state.item.hours), 2);
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size="lg">
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggleModal}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} habitación
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Nombre:</Label>
                                            <Input name="name" value={item.name} onChange={this.changed}
                                                   invalid={errors.name != null}/>
                                            <FormFeedback>{errors.name}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <div>Editar precio</div>
                                            <AppSwitch
                                                className="mdl"
                                                color="primary"
                                                variant="pill"
                                                checked={item.edit_price === '1'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        ...this.state,
                                                        item: {
                                                            ...this.state.item,
                                                            edit_price: e.target.checked ? '1' : '0'
                                                        }
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                        <FormGroup>
                                            <div>Orden</div>
                                            <Input name="sort" value={item.sort} onChange={this.changed}
                                                   invalid={errors.sort != null} type="number"/>
                                            <FormFeedback>{errors.sort}</FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    {this.state.categories.map((o, i) => {
                                        o.items.forEach(o => {
                                            o.value = o.id;
                                            o.label = o.name;
                                        });

                                        let index = this.getIndex(o);

                                        if (index >= 0) {
                                            return this.state.item.categories.length > 0 && (<Col md={6}>
                                                <FormGroup>
                                                    <Label>{o.name}</Label>
                                                    <Select
                                                        classNamePrefix="react-select"
                                                        placeholder="Buscar..."
                                                        options={o.items}
                                                        value={this.state.categories[index].items.filter(o => o.id == item.categories[index].value)}
                                                        onChange={selected => {
                                                            if (selected) {
                                                                console.log("SELECTED: ", selected);
                                                                let option = new Object();
                                                                option.category = o.id;
                                                                option.value = selected.id;
                                                                item.categories.splice(index, 1, option);
                                                                this.setState(this.state);
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        }
                                    })}

                                    <Col md={12}></Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Detalle:</Label>
                                            <Input type='textarea'
                                                   name="detail"
                                                   value={item.detail}
                                                   onChange={this.changed}
                                                   rows={3}
                                                   invalid={errors.detail != null}/>
                                            <FormFeedback>{errors.detail}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={6}>
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Precio:</Label>
                                            <Input name="price"
                                                   value={item.price}
                                                   type="number"
                                                   min="1"
                                                   max="20"
                                                   onChange={this.changed}
                                                   invalid={errors.price != null}/>
                                            <FormFeedback>{errors.price}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Horas:</Label>
                                            <Input type="number"
                                                   name="hours"
                                                   value={item.hours}
                                                   onChange={this.changed}
                                                   invalid={errors.hours != null}/>
                                            <FormFeedback>{errors.hours}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Pre. x Hor:</Label>
                                            <Input value={price_hour}
                                                   readonly
                                                   className="pointer"
                                                   onClick={() => {
                                                       this.setState({
                                                           item: {
                                                               ...this.state.item,
                                                               price_hour: price_hour_val
                                                           }
                                                       })
                                                   }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Precio por hora:</Label>
                                            <Input type="number"
                                                   name="price_hour"
                                                   value={item.price_hour}
                                                   onChange={this.changed}
                                                   invalid={errors.price_hour != null}/>
                                            <FormFeedback>{errors.price_hour}</FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col sm="12">
                                        <Label>Tipos de servicios</Label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            isClearable
                                            classNamePrefix="react-select"
                                            placeholder="Buscar..."
                                            loadOptions={(word, callback) => {
                                                Api.get('/type_services', {word}, rsp => {
                                                    if (rsp.ok) {
                                                        callback(rsp.items.map(o => ({
                                                            value: o.id,
                                                            label: o.name,
                                                        })));
                                                    } else {
                                                        callback([]);
                                                    }
                                                }, false);
                                            }}
                                            onChange={selected => {
                                                if (selected) {
                                                    let exist = false;
                                                    item.type_services.forEach(item => {
                                                        if (item.id === selected.value) {
                                                            exist = true;
                                                        }
                                                    });

                                                    if (!exist) {
                                                        item.type_services.push({
                                                            id: selected.value,
                                                            name: selected.label
                                                        });
                                                        this.setState(this.state);
                                                    }
                                                }
                                            }}
                                        />

                                        {item.type_services.length > 0 && (
                                            <Table bordered size="sm" className="mrg-t-10 mdl-td _tbl mrg-0">
                                                <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th width="1%"/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {item.type_services.map((o, i) => (
                                                    <tr key={i} style={{'background': (i % 2) ? '#F0F3F5' : ''}}>
                                                        <td>{o.name}</td>
                                                        <td>
                                                            <Button color="danger"
                                                                    size="sm"
                                                                    className="m-0"
                                                                    onClick={() => {
                                                                        item.type_services.splice(i, 1);
                                                                        this.setState(this.state);
                                                                    }}><i className="fa fa-times"/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
