import React from 'react';
import {
    Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Alert from "../inc/alert";
import _component from "./_component";
import Select from "react-select";
import clone from "clone-deep";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import Util from "../inc/utils";

const STATE = {
    item: {
        id: 0,
        name: '',
        detail: '',
        sort: '0'
    },
    loading: false,
    error: null,
    errors: {},
    modal: false,
};

export default class CategoriesM extends _component {

    state = clone(STATE);

    add = () => {
        this.setState({
            ...clone(STATE),
            modal: true,
        });
    };

    edit = (id) => {
        this.setState({
            ...clone(STATE),
            modal: true,
            loading: true,
        });
        Api.get('/prices/form', {id: id}, (rsp) => {
            if (rsp.ok) {
                rsp.item.password = '';
                this.setState({
                    loading: false,
                    item: rsp.item,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };


    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            errors: {},
            error: null
        });

        const item = {...this.state.item};
        const data = new FormData();
        Object.keys(item).map(key => data.append(key, item[key]));

        Api.post('/prices/create', data, (rsp) => {

            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.toggleModal();
                if (typeof this.props.callback === 'function') this.props.callback({
                    ...this.state.item,
                    id: rsp.id
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg,
                    errors: rsp.errors
                });
            }
        });
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/prices/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggleModal();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...');
        });
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}>
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        {item.id > 0 ? 'Editar' : 'Agregar'} precio
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        <Row className="form-group">
                            <Col md="4">Horas</Col>
                            <Col md="8">
                                <Input type="integer" name="hours" value={item.hours} onChange={this.changed}
                                       invalid={errors.hours != null}/>
                                <FormFeedback>{errors.hours}</FormFeedback>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md="4">Precio</Col>
                            <Col md="8">
                                <Input type="integer" name="price" value={item.price} onChange={this.changed}
                                       invalid={errors.price != null}/>
                                <FormFeedback>{errors.price}</FormFeedback>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}
                        <Button color="secondary" outline onClick={this.toggleModal}>
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={this.save}>
                            <i className="fa fa-check"/> Guardar
                        </Button>
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}
