import React, {Component} from 'react';
import {
    Button, CardBody, Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table
} from 'reactstrap';
import {Api} from "../inc/api";
import {toast} from "react-toastify";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import _component from "./_component";
import Alert from "../inc/alert";
import {User} from "../inc/user";
import Util from "../inc/utils";

const ITEM = {
    id: 0,
    opening: 0,
    moneyIncome: 0,
    moneyExpenditures: 0,
    moneyReceived: 0,
    state: 0,
    items: [],
};

export default class BoxsM extends _component {

    _state = {
        item: ITEM,
        loading: false,
        error: null,
        modal: false,
        errors: {},
    };

    state = {...this._state};

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    add = () => {
        this.setState({
            ...this._state,
            loading: false,
            modal: true,
        });
    };

    edit = (id) => {
        this.setState({
            ...this._state,
            modal: true,
            loading: true,
        });
        Api.get('/boxs/dataForm', {id: id}, rsp => {
            if (rsp.ok) {
                this.setState({
                    loading: false,
                    item: rsp.item,
                });
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg
                });
            }
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null,
            errors: {}
        });

        const data = new FormData();
        Object.keys(this.state.item).map(key => data.append(key, this.state.item[key]));

        Api.post('/boxs/create', data, (rsp) => {

            if (rsp.ok) {
                toast.success('Guardado correctamente');
                this.toggle();
                if (typeof this.props.callback === 'function') this.props.callback();
            } else {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: rsp.msg,
                    errors: rsp.errors
                });
            }

        });
    };

    closeBox = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/boxs/close_box', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Caja cerrada correctamente');
                    this.toggle();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            });
        });
    };

    remove = () => {
        Alert.confirm(() => {
            Alert.loading();
            Api.get('/boxs/remove', {id: this.state.item.id}, rsp => {
                Alert.loading(false);
                if (rsp.ok) {
                    toast.success('Eliminado correctamente');
                    this.toggle();
                    if (typeof this.props.callback === 'function') this.props.callback();
                } else {
                    Alert.error(rsp.msg);
                }
            }, 'Eliminando...');
        });
    };

    render() {
        const {item, errors} = this.state;
        return (
            <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static" keyboard={false}
                   size={item.id == 0 ? 'md' : 'lg'}>
                <fieldset disabled={this.state.loading}>
                    <ModalHeader toggle={this.toggle}>
                        {item.id > 0 ? 'Editar' : 'Abrir'} Caja
                    </ModalHeader>
                    <ModalBody className="form-horizontal">

                        <div className="alert alert-danger" hidden={!this.state.error}>{this.state.error}</div>

                        {item.id == 0 && (
                            <Row className="form-group">
                                <Col md="4">Monto de apertura</Col>
                                <Col md="8">
                                    <Input name="opening" value={item.opening} onChange={this.changed}
                                           invalid={errors.opening != null} type="number"/>
                                    <FormFeedback>{errors.opening}</FormFeedback>
                                </Col>
                            </Row>
                        )}

                        {item.id > 0 && (
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <label>Apertura</label>
                                        <Input readOnly
                                               value={User.stg.coin + ' ' + Util.num(item.opening, 2)}
                                               className="bg-white"/>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <label>Ingresos</label>
                                        <Input readOnly
                                               value={User.stg.coin + ' ' + Util.num(item.moneyIncome, 2)}
                                               className="bg-white"/>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <label>Egresos</label>
                                        <Input readOnly
                                               value={User.stg.coin + ' ' + Util.num(item.moneyExpenditures, 2)}
                                               className="bg-white"/>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <label>Total en caja</label>
                                        <Input readOnly
                                               value={User.stg.coin + ' ' + Util.num(item.moneyReceived, 2)}
                                               className="bg-white"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}

                        {item.items.length > 0 && (
                            <Table borderless responsive style={{'borderTop': '2px solid #F0F3F5'}}>
                                <thead>
                                <tr>
                                    <th width="1%">#</th>
                                    <th>Razón</th>
                                    <th width="1%">Monto</th>
                                    <th width="1%">Fecha de creación</th>
                                </tr>
                                </thead>
                                <tbody>
                                {item.items.map((o, i) => (
                                    <tr style={{'background': (i % 2) ? '' : '#F0F3F5'}}>
                                        <td className="nowrap">{o.id_report}</td>
                                        <td>{o.reason}</td>
                                        <td className="nowrap">{User.stg.coin + ' ' + Util.num(o.amount, 2)}</td>
                                        <td className="nowrap">{o.date_created.datetime()}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        )}

                    </ModalBody>
                    <ModalFooter>
                        {item.id > 0 && item.state != 1 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.remove}>
                                Eliminar
                            </Button>
                        )}

                        {item.id > 0 && item.state == 1 && (
                            <Button color="danger" style={{marginRight: 'auto'}} outline onClick={this.closeBox}>
                                Cerrar Caja
                            </Button>
                        )}

                        <Button color="secondary" outline onClick={this.toggle}>
                            Salir
                        </Button>

                        {item.id == 0 && (
                            <Button color="primary" onClick={this.save}>
                                <i className="fa fa-check"/> Guardar
                            </Button>
                        )}
                    </ModalFooter>
                </fieldset>
            </Modal>
        );
    }
}